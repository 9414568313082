@use "../abstracts" as *;
@use "../components/components";
@use "sass:math";

.sec-ranking {
  &.-top {
    @include mq(min-md) {
      .form-rankingSearch {
        display: block !important;
        &_toggle {
          display: none;
        }
      }
    }
  }
  .seminarInfo {
    display: none;
    &.-max {
      display: block;
    }
  }
}

.form-rankingSearch {
  $this: &;
  background-color: #fff;
  border: 2px solid $color-accent;
  @include mq(min-md) {
    padding: math.percentage(math.div(38, 1100));
  }
  @include mq(md) {
    padding: math.percentage(math.div(18, 327));
  }
  + * {
    margin-top: 56px;
  }
  &_lineList {
    padding-left: 0;
    list-style: none;
    @include mq(md) {
      margin-top: -15px;
    }
  }
  &_line {
    position: relative;
    @include mq(min-md) {
      display: flex;
      gap: 40px;
      align-items: center;
      padding: 0 0 24px;
      + li {
        padding-top: 23px;
      }
    }
    @include mq(md) {
      flex-wrap: wrap;
    }
    &::after {
      display: block;
      height: 1px;
      @extend %dotLine;
      @include mq(min-md) {
        content: "";
      }
      @include mq(md) {
        content: none;
      }
    }
  }
  &_dl {
    position: relative;
    margin: 0;
    @include mq(min-md) {
      display: flex;
      flex: auto;
    }
    @include mq(md) {
      padding: 15px 0 16px;
    }
    &::after {
      display: block;
      height: 1px;
      @extend %dotLine;
      @include mq(min-md) {
        content: none;
      }
      @include mq(md) {
        content: "";
      }
    }
    &:nth-of-type(n + 2) {
      dt {
        width: auto;
      }
    }
  }
  &_dt {
    padding-right: 1.5em;
    font-weight: bold;
    line-height: 1.5;
    @include mq(min-md) {
      align-self: center;
      width: 120px;
    }
    @include mq(md) {
      + * {
        margin-top: 0.6em;
      }
    }
  }
  &_dd {
    flex: 1;
    margin-left: 0;
    input,
    textarea,
    select {
      min-height: 50px;
      padding: 0.74em 1em;
    }
    .form-checkBox {
      @include mq(md) {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(135px, 1fr));
        gap: 2em 0.5em;
      }
    }
  }
  &_btn {
    margin-top: 23px;
    .formSet & {
      button {
        @include mq(min-md) {
          min-width: 280px;
        }
        @include mq(md) {
          min-width: 184px;
        }
        &::before {
          position: absolute;
          top: 0;
          bottom: 0;
          left: calc(24em / 16);
          display: block;
          width: calc(20em / 16);
          aspect-ratio: 1 / 1;
          margin: auto;
          content: "";
          background-color: #fff;
          transition: background-color 0.2s;
          mask-image: url("/assets/images/common/icon-search.svg");
          mask-size: contain;
          mask-repeat: no-repeat;
          mask-position: center center;
        }
        &::after {
          content: none;
        }
      }
    }
  }
  &_toggle {
    display: flex;
    @include mq(min-md) {
      justify-content: flex-end;
    }
    @include mq(md) {
      justify-content: center;
    }
    &.-open {
      #{$this}_toggleBtn {
        padding-bottom: 0.5em;
        border-radius: 16px 16px 0 0;
        transition: padding 0.3s, border-radius 0.3s 0s;
        &::after {
          transform: translateY(0.2em) rotate(-45deg);
        }
      }
    }
    + #{$this} {
      display: none;
    }
    &Btn {
      position: relative;
      padding: 0.7em 40px 0.7em 24px;
      font-weight: bold;
      line-height: 1.4;
      color: #fff;
      text-align: center;
      cursor: pointer;
      background-color: $color-accent;
      border-radius: 16px;
      transition: padding 0.3s, border-radius 0.3s 0.5s;
      @include mq(md) {
        width: 200px;
      }
      &::after {
        position: absolute;
        top: 1.1em;
        right: calc(17em / 16);
        width: calc(7em / 16);
        aspect-ratio: 1 / 1;
        content: "";
        border-color: currentColor;
        border-style: solid solid none none;
        border-width: 2px;
        transform: translateY(-0.1em) rotate(135deg);
      }
    }
  }
}

.resultHeader {
  display: flex;
  margin-top: 40px;
  @include mq(min-md) {
    align-items: flex-end;
    justify-content: space-between;
  }
  @include mq(md) {
    flex-direction: column;
    gap: 1em;
  }
  + * {
    margin-top: 1.2em;
  }
  &_ttl {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.2;
  }
  &_num {
    font-size: 18px;
    font-weight: normal;
    color: #727272;
  }
  &_sort {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    line-height: 1.2;
  }
  &_sortTtl {
    &::after {
      content: "：";
    }
  }
  &_sortItem {
    margin-left: 0.5em;
    + * {
      margin-left: 1em;
    }
  }
  &_sortLink {
    color: #a7a7a7;
    transition: color 0.2s;
    @media (hover: hover) {
      &:hover {
        color: $color-accent;
      }
    }
    &.-active {
      color: $color-accent;
      text-decoration: none;
      pointer-events: none;
      cursor: default;
    }
  }
}

.sec-industries {
  background-color: $color-bg-blue;
}

.list-industries {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 4px;
  padding: 0;
  list-style: none;
  &_item {
    aspect-ratio: 1 / 1;
    background-color: #fff;
    img {
      margin: auto 0;
    }
  }
  &_link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    @include mq(min-md) {
      padding: math.percentage(math.div(20, 180)) math.percentage(math.div(16, 180)) math.percentage(math.div(12, 180));
    }
    @include mq(md) {
      padding: math.percentage(math.div(16, 162)) math.percentage(math.div(13, 162)) math.percentage(math.div(12, 162));
    }
    color: $color-text-base;
    text-decoration: none;
  }
  &_ttl {
    margin: auto 0;
    font-weight: bold;
    line-height: 1.38;
    text-align: center;
    @include mq(min-md) {
      font-size: 16px;
    }
    @include mq(md) {
      font-size: 15px;
    }
  }
}

.tooltip {
  position: relative;
  dt {
    display: inline-block;
    padding-left: 1.5em;
    color: $color-accent;
    text-decoration: underline;
    cursor: pointer;
    &::before {
      position: absolute;
      top: 0.3em;
      left: 0;
      display: block;
      width: 1em;
      height: 1em;
      content: "";
      background-color: $color-accent;
      mask-image: url("/assets/images/common/icon-information.svg");
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-position: center center;
    }
    &:hover {
      text-decoration: none;
      + dd {
        pointer-events: all;
        box-shadow: 4px 8px 16px 0 rgba(0, 0, 0, 0.16);
        opacity: 1;
        transition: opacity 0.2s, box-shadow 1s 0.1s;
      }
    }
  }
  dd {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    width: 550px;
    max-width: 100%;
    padding: 1.5em;
    margin: 20px 0 0;
    line-height: 1.63;
    pointer-events: none;
    background-color: #fff;
    border: 2px solid $color-accent;
    opacity: 0;
    transition: opacity 0.2s;
    &::before {
      position: absolute;
      bottom: 100%;
      left: math.percentage(math.div(116, 550));
      display: block;
      width: 14px;
      height: 16px;
      clip-path: polygon(50% 0, 100% 100%, 0 100%);
      content: "";
      background-color: $color-accent;
    }
  }
}

.sec-postDetails {
  pre {
    padding: 0.5em 0.8em;
    margin-top: 1.5em;
    font-family: $font-code;
    font-size: 14px;
    font-weight: 500;
    color: #eee;
    color: $color-text-base;
    white-space: pre-wrap;
    background-color: #f6f6f6;
    border: 1px solid #1d1c1d21;
    border-radius: 3px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  code {
    display: inline-block;
    padding: 0.1em 0.3em;
    margin: 0 0.2em;
    font-family: $font-code;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4;
    color: rgb(224, 30, 90);
    background-color: #f6f6f6;
    border: 1px solid #1d1c1d21;
    border-radius: 3px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  hr {
    display: block;
    height: 40px;
    margin: 40px -40px;
    background-color: $color-bg-base;
    border-style: none;
    @include mq(min-md) {
      margin: math.percentage(math.div(40, 1020)) math.percentage(math.div(-40, 1020));
    }
    @include mq(md) {
      margin: 32px math.percentage(math.div(-24, 279));
    }
    + *,
    + h2:nth-child(n) {
      margin-top: 0;
    }
  }
  h2:not(.ttl-block) {
    margin-top: 50px;
    font-size: 24px;
    font-weight: bold;
    &:first-child {
      margin-top: 0;
    }
    + * {
      margin-top: 1.5em;
    }
  }
  h3 {
    margin-top: 30px;
    &:first-child {
      margin-top: 0;
    }
    + * {
      margin-top: 20px;
    }
  }
  .ttl-block {
    + * {
      margin-top: 1.5em;
    }
  }
  .-color-accent {
    color: $color-accent;
  }
  .-color-dark {
    color: $color-dark;
  }
  .-color-red {
    color: #c31616;
  }
  table {
    &.ranking {
      &.-left {
        margin-right: auto;
        margin-left: 0;
      }
      &.-center {
        margin-right: auto;
        margin-left: auto;
      }
      &.-right {
        margin-right: 0;
        margin-left: auto;
      }
      td {
        @extend %textAlign;
      }
    }
    &.tb-price {
      width: auto;
      @include mq(min-md) {
        min-width: 80%;
      }
      tbody {
        td:nth-child(n) {
          width: auto;
        }
      }
    }
    caption {
      @extend %textAlign;
      margin-top: 0.5em;
      font-size: 0.8em;
      font-size: 14px;
      line-height: 1.5;
      text-align: right;
      caption-side: bottom;
    }
  }
  .list-dot {
    li {
      padding-left: calc(16em / 12);
    }
  }
  .list-note {
    font-size: 1em;
  }
  * {
    @extend %textAlign;
  }
  .box-contact_link {
    width: 100%;
    padding: 1em 1em !important;
    margin: 50px 0 0 !important;
    text-align: center;
    border: 1px solid #ddd !important;
    + * {
      margin-top: 50px;
    }
    u {
      color: inherit !important;
      font {
        color: inherit !important;
      }
    }
  }
  br + .box-contact_link {
    margin-top: 10px !important;
  }
  .box-related {
    position: relative;
    margin-top: 50px;
    border: 4px solid #f4f4f4;
    @include mq(min-md) {
      padding: 20px math.percentage(math.div(36, 1020));
    }
    @include mq(md) {
      padding: 20px math.percentage(math.div(16, 279));
    }
    &_ttl:nth-child(n) {
      position: absolute;
      top: 0;
      @include mq(min-md) {
        left: math.percentage(math.div(36, 1020));
      }
      @include mq(md) {
        left: math.percentage(math.div(16, 279));
      }
      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-height: 29px;
      padding: 2px 11px 2px 51px;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.2;
      color: #fff;
      background-color: $color-dark;
      transform: translateY(-50%);
      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 16px;
        display: block;
        width: 25px;
        height: 16px;
        margin: auto;
        content: "";
        background-image: url("/assets/images/common/icon-finger_right.svg");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }
      &::after {
        position: absolute;
        top: 0;
        left: 100%;
        display: block;
        width: 10px;
        height: 100%;
        clip-path: polygon(-2% 0%, 100% 100%, -2% 100%);
        content: "";
        background-color: $color-dark;
      }
    }
    .list-article {
      margin: 0;
      @include mq(md) {
        line-height: 1.47;
      }
      &_item {
        position: relative;
        &:not(:last-child)::after {
          @extend %dotLine;
        }
      }
      &_link {
        min-height: 64px;
        border-bottom: none;
      }
    }
  }
  .-txt-s {
    font-size: calc(14em / 16);
  }
  .-txt-l {
    font-size: calc(20em / 16);
  }
}
