@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes stretch {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
