@use "../abstracts" as *;
@use "../components/components";
@use "sass:math";

.sec-policy {
  .panel {
    @include mq(min-md) {
      padding: math.percentage(math.div(40, 1100));
    }
    @include mq(md) {
      padding: 32px math.percentage(math.div(24, 327));
    }
  }
  .ttl-block {
    margin-top: 2.5em;
    color: $color-text-base;
    background-color: #edeff3;
    &:first-child {
      margin-top: 0;
    }
  }
  .box-line {
    .ttl-content2 {
      @include mq(min-md) {
        font-size: 16px;
      }
      @include mq(md) {
        font-size: 15px;
      }
    }
  }
}

.txt-policyInfo {
  margin-top: 3em;
  text-align: right;
}
