@use "../abstracts" as *;
@use "sass:math";

.gnavBg {
  position: fixed;
  z-index: 99;
  display: block;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  background-color: rgba(#000, 0.6);
  opacity: 0;
  transition: opacity 0.9s;
  .-gnavOpen & {
    pointer-events: all;
    opacity: 1;
  }
}

.nav-megaMenu {
  $this: &;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  transition: transform 0.5s ease-out;
  transform: translateY(-100%);
  @include mq(min-hd) {
    z-index: 0;
    padding: 60px 0 80px;
  }
  @include mq(hd) {
    z-index: -1;
    height: calc(100dvh - 48px);
    padding: 0 0 56px;
    overflow-y: auto;
    > .container {
      padding: 0;
    }
  }
  .-gnavOpen & {
    overflow-y: scroll;
    transform: translateY(0%);
  }
  &_wrap {
    display: flex;
    @include mq(min-hd) {
      justify-content: space-between;
    }
    @include mq(hd) {
      flex-direction: column;
    }
  }
  &_list,
  &_subList {
    padding-left: 0;
    list-style: none;
  }
  &_item,
  &_subItem {
  }
  &_link,
  &_subLink {
    position: relative;
    color: $color-text-base;
    text-decoration: none;
    transition: color 0.3s;
    @media (hover: hover) {
      &:hover {
        // text-decoration: underline;
        // color: #1955aa;
        color: $color-accent;
      }
    }
    @include mq(hd) {
      display: flex;
      align-items: center;
      padding: 0.5em math.percentage(math.div(40, 375)) 0.5em math.percentage(math.div(20, 375));
      &::after {
        position: absolute;
        top: 0;
        right: math.percentage(math.div(20, 375));
        bottom: 0;
        z-index: 1;
        width: 7px;
        height: 7px;
        // aspect-ratio: 1 / 1;
        margin: auto;
        content: "";
        border-color: currentColor;
        border-style: solid solid none none;
        border-width: 2px;
        transform: rotate(45deg);
      }
    }
  }
  &_list {
    font-weight: bold;
    line-height: 1.5;
    @include mq(min-lg) {
      font-size: 18px;
    }
    @include mq(min-hd) {
      & + & {
        margin-top: 2.8em;
      }
    }
    @include mq(hd) {
      font-size: 16px;
    }
    &.-small {
      font-size: 14px;
      font-weight: normal;
      @include mq(hd) {
        margin-top: 30px;
      }
      #{$this}_item {
        border-bottom-style: none;
      }
      #{$this}_link {
        @include mq(hd) {
          min-height: 48px;
          &::after {
            content: none;
          }
        }
      }
    }
  }
  &_item {
    @include mq(min-hd) {
      & + & {
        margin-top: 1.3em;
      }
    }
    @include mq(hd) {
      border-bottom: 1px solid #ddd;
    }
  }
  &_link {
    @include mq(hd) {
      min-height: 56px;
      &.-sub {
        &::before,
        &::after {
          position: absolute;
          top: 0;
          right: math.percentage(math.div(20, 375));
          bottom: 0;
          display: block;
          width: 10px;
          height: 2px;
          margin: auto;
          content: "";
          background-color: #333;
          transform: none;
        }
        &::after {
          transition: transform 0.3s;
          transform: rotate(-90deg);
        }
        &.-open {
          &::after {
            transform: rotate(0deg);
          }
        }
      }
    }
  }
  &_toggle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include mq(min-hd) {
      display: none;
    }
    @include mq(hd) {
      display: block;
    }
  }
  &_subList {
    @include mq(min-hd) {
      display: block !important;
      margin-top: 1.1em;
      font-size: 16px;
    }
    @include mq(hd) {
      display: none;
      font-size: 15px;
      background-color: #f4f4f4;
    }
  }
  &_subItem {
    @include mq(min-hd) {
      & + & {
        margin-top: 0.7em;
      }
    }
    @include mq(hd) {
      border-top: 1px solid #ddd;
      &.-sp {
        display: block !important;
      }
    }
    // &.-top {
    //   @include mq(min-hd) {
    //     display: none;
    //   }
    // }
  }
  &_subLink {
    position: relative;
    min-height: 47px;
    @include mq(min-hd) {
      padding-left: 1em;
    }
    &::before {
      left: 0;
      content: "・";
      @include mq(min-hd) {
        position: absolute;
      }
    }
  }
  &_info {
    display: flex;
    flex-direction: column;
    height: 100%;
    @include mq(min-hd) {
      width: 280px;
    }
    @include mq(hd) {
      max-width: 480px;
      padding: 30px math.percentage(math.div(24, 375)) 0px;
      margin: 0 auto;
    }
    .btn {
      width: 100%;
      margin-top: 32px;
      a {
        width: 100%;
      }
    }
  }
}

.l-header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  background-color: #fff;
  @include mq(min-hd) {
    height: 70px;
  }
  @include mq(hd) {
    height: 48px;
  }
  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    content: "";
    background-color: #e5e5e5;
  }
  &.-wait {
    position: fixed;
    transition: none;
    transform: translateY(-105%);
    &.-adjust {
      transition: transform 0.5s;
      &.-fixed {
        transform: translateY(0);
        .admin-bar & {
          @include mq(hd) {
            top: 0;
          }
        }
      }
    }
  }
  > .container {
    max-width: none;
    background-color: #fff;
    @include mq(min-hd) {
      position: relative;
      z-index: 10;
      padding: 13px 28px;
    }
    @include mq(hd) {
      height: 100%;
      padding: 0;
    }
    .lenis-stopped & {
      overflow-y: scroll;
    }
  }
  &_inr {
    display: flex;
    align-items: center;
    @include mq(hd) {
      height: 100%;
      padding-left: math.percentage(math.div(16, 375));
    }
    .btn {
      margin: 0 calc(18vw / 1366 * 100);
      transition: opacity 0.9s;
      @include mq(hd) {
        display: none;
      }
      > a {
        min-width: auto;
        height: 43px;
        padding-right: 2em;
        padding-left: 1.2em;
      }
      .-gnavOpen & {
        pointer-events: none;
        opacity: 0;
      }
    }
  }
}

.logo-header {
  margin-right: auto;
  font-size: 12px;
  line-height: 1;
  @include mq(min-hd) {
    width: 202px;
    max-width: 20.5%;
  }
  @include mq(hd) {
    width: 142px;
  }
  &_link {
    display: block;
  }
}

.nav-header {
  $this: &;
  padding-left: 0;
  margin: 0;
  list-style: none;
  transition: opacity 0.9s;
  @include mq(min-hd) {
    display: flex;
    gap: calc(36vw / 1366 * 100);
    gap: 0px;
    margin-left: 20px;
  }
  @include mq(hd) {
    display: none;
  }
  &_item {
    position: relative;
    font-weight: bold;
    line-height: 1.5;
    &.-sub {
      #{$this}_link {
        &::after {
          top: 0;
          right: calc(16em / 16);
          bottom: 0;
          width: calc(7em / 16);
          height: calc(7em / 16);
          // aspect-ratio: 1 / 1;
          margin: auto;
          margin-left: 0.5em;
          content: "";
          border-color: $color-accent;
          border-style: solid solid none none;
          border-width: 2.5px;
          border-width: calc(2.5em / 16);
          transition: transform 0.3s;
          transform: translateY(-20%) rotate(135deg);
        }
        @media (hover: hover) {
          &:hover {
            color: $color-accent;
            background-color: $color-bg-blue;
          }
        }
      }
      &.-open {
        #{$this}_link {
          &::after {
            transform: rotate(45deg);
          }
        }
      }
    }
  }
  &_link {
    display: flex;
    align-items: center;
    height: 44px;
    padding: 0 calc(18vw / 1366 * 100);
    color: $color-text-base;
    text-decoration: none;
    transition: color 0.3s, background-color 0.3s;
    @media (hover: hover) {
      &:hover {
        color: $color-accent;
      }
    }
  }
  &_subList {
    position: absolute;
    display: none;
    width: 100%;
    padding-left: 0;
    font-size: 14px;
    line-height: 1.5;
    list-style: none;
    background-color: #e5e5e5;
  }
  &_subItem {
    margin: 1px;
  }
  &_subLink {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 44px;
    padding: 0 calc(18vw / 1366 * 100);
    color: $color-text-base;
    text-decoration: none;
    background-color: #fff;
    transition: background-color 0.3s, color 0.3s;
    @media (hover: hover) {
      &:hover {
        color: $color-accent;
        background-color: $color-bg-blue;
      }
    }
  }
  .-gnavOpen & {
    pointer-events: none;
    opacity: 0;
  }
}

.btn-gnav {
  $this: &;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  aspect-ratio: 44 / 44;
  margin: 0;
  line-height: 1;
  cursor: pointer;
  @include mq(min-hd) {
    width: 44px;
  }
  @include mq(hd) {
    width: 64px;
    height: 100%;
  }
  &_inr {
    position: relative;
    display: block;
    text-indent: -999em;
    @include mq(min-hd) {
      width: 40px;
      height: 26px;
    }
    @include mq(hd) {
      width: 24px;
      height: 18px;
    }
    i,
    &::before,
    &::after {
      position: absolute;
      top: auto;
      bottom: auto;
      left: 0;
      display: block;
      width: 100%;
      height: 2px;
      content: "";
      background-color: $color-text-base;
      transition: transform 0.3s;
    }
    i {
      top: 0;
      bottom: 0;
      margin: auto;
      transition: opacity 0.3s;
    }
    &::before {
      top: 0;
      transform-origin: left top;
    }
    &::after {
      bottom: 0;
      transform-origin: left bottom;
    }
  }
  .-gnavOpen & {
    &_inr {
      i {
        opacity: 0;
      }
      &::before {
        @include mq(min-hd) {
          transform: rotate(32deg) scale(1.2, 0.9);
        }
        @include mq(hd) {
          transform: rotate(36deg) scale(1.2, 0.9);
        }
      }
      &::after {
        @include mq(min-hd) {
          transform: rotate(-32deg) scale(1.2, 0.9);
        }
        @include mq(hd) {
          transform: rotate(-36deg) scale(1.2, 0.9);
        }
      }
    }
  }
}

.hd-search {
  position: relative;
  &_field {
    padding: 0;
    margin: 0;
    border-style: none;
    font {
      position: absolute;
    }
  }
  &_input {
    width: 100%;
    padding: 1em 3em 1em 1em;
    line-height: 1.4;
    background-color: #f4f4f4;
    border: 1px solid #ddd;
    @include mq(min-hd) {
      font-size: 16px;
    }
    @include mq(hd) {
      font-size: 15px;
    }
    &::placeholder {
      color: #a7a7a7;
    }
  }
  &_icon {
    position: absolute;
    top: 0;
    right: 1em;
    bottom: 0;
    display: block;
    width: 20px;
    height: 20px;
    // aspect-ratio: 20 / 20;
    margin: auto;
    text-indent: -999em;
    cursor: pointer;
    background-color: $color-text-base;
    border-style: none;
    transition: background-color 0.2s;
    mask-image: url("/assets/images/common/icon-search.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center center;
    @media (hover: hover) {
      &:hover {
        background-color: $color-accent;
      }
    }
  }
}

.hd-sns {
  display: flex;
  align-items: center;
  justify-content: center;
  @include mq(min-hd) {
    margin-top: auto;
  }
  @include mq(hd) {
    margin-top: 40px;
  }
  &_head {
    flex: auto;
    text-align: center;
    border-right: 1px solid #dddddd;
  }
  &_body {
    flex: auto;
    padding-left: 0;
    margin-left: 0;
  }
}
