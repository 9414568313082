@use "../abstracts" as *;
@use "../components/components";
@use "sass:math";

.sec-wordsToc {
  padding: 16px 0;
  background-color: #e1e5eb;
}

.nav-wordsToc {
  padding: 8px 0;
  @include mq(md) {
    display: flex;
    margin: 0 math.percentage(math.div(-24, 327));
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 0px;
    }
    &::before,
    &::after {
      display: block;
      flex-shrink: 0;
      width: math.percentage(math.div(24, 375));
      content: "";
    }
  }
  &_list {
    padding-left: 0;
    list-style: none;
    @include mq(min-md) {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(105px, 1fr));
      gap: 1em;
    }
    @include mq(md) {
      display: flex;
      gap: 12px;
    }
  }
  &_item {
    @include mq(md) {
      min-width: 88px;
    }
  }
  &_link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 40px;
    padding: 0.2em 35px 0.2em 20px;
    line-height: 1.2;
    color: $color-dark;
    text-decoration: none;
    white-space: nowrap;
    background-color: #fff;
    border-radius: 20px;
    transition: background-color 0.3s;
    &::after {
      position: absolute;
      top: 0;
      right: calc(16em / 16);
      bottom: 0;
      width: calc(7em / 16);
      height: calc(7em / 16);
      // aspect-ratio: 1 / 1;
      margin: auto;
      content: "";
      border-color: currentColor;
      border-style: solid solid none none;
      border-width: 2.5px;
      border-width: calc(2em / 16);
      transform: translateY(-0.1em) rotate(135deg);
    }
    @media (hover: hover) {
      &:hover {
        color: #fff;
        background-color: $color-dark;
      }
    }
  }
}

.sec-words {
  padding-top: 40px;
  .panel {
    @include mq(min-md) {
      padding: math.percentage(math.div(40, 1100));
    }
    @include mq(md) {
      padding: 32px math.percentage(math.div(24, 327)) 40px;
    }
  }
  .ttl-block {
    color: $color-text-base;
    background-color: #edeff3;
  }
}

.list-words {
  padding-left: 0;
  margin-top: 18px;
  list-style: none;
  @include mq(min-md) {
    column-count: 3;
    column-gap: 10%;
    // column-gap: 30px;
    // transform: translate3d(0, 0, 0);
  }
  &_item {
    padding: 0.4em 0;
    line-height: 1.63;
    page-break-inside: avoid;
    break-inside: avoid;
    + li {
      // margin-top: 0.8em;
    }
  }
  &_link {
    display: inline-block;
  }
}
