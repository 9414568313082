@use "../abstracts" as *;

.wrapper {
  overflow: hidden;
  @include mq(min-hd) {
    padding-top: 70px;
  }
  @include mq(hd) {
    padding-top: 48px;
  }
}

.container {
  @include containerLayout();
}

.-pc {
  @include mq(md) {
    display: none !important;
  }
}

.-sp {
  display: none !important;
  @include mq(md) {
    display: block !important;
  }
}

.-hd {
  @include mq(min-hd) {
    display: none !important;
  }
}

.-lg {
  @include mq(lg) {
    display: none;
  }
}

.-ib {
  display: inline-block;
}

// ▼ You Tubeレスポンシブ対応用Box
.box-movie {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  iframe {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.-target,
.-ready,
.-action {
  /* Class for scroll animation */
}

// ▼ Lenis
html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}
// ▲ Lenis

.txt-lightGray {
  color: #a7a7a7;
}
