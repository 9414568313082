@use "../abstracts" as *;
@use "../components/components";
@use "sass:math";

.sec-aboutUs {
  .panel {
    @include mq(min-md) {
      padding: math.percentage(math.div(40, 1100));
    }
    @include mq(md) {
      padding: 32px math.percentage(math.div(24, 327));
    }
  }
  .list-3column,
  .list-inline {
    + .list-note {
      position: relative;
      padding-top: 15px;
      margin-top: 30px;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        height: 1px;
        content: "";
        @extend %dotLine;
      }
    }
  }
}

.aboutUs {
  &_iso {
    margin-top: 33px;
    text-align: center;
  }
  &_sign {
    text-align: right;
    img {
      width: 135px;
    }
  }
}

.company {
  @include mq(min-md) {
    display: flex;
    gap: math.percentage(math.div(42, 1020));
    align-items: flex-end;
    justify-content: space-between;
  }
  @include mq(md) {
    display: block;
  }
  &_tb {
    @include mq(min-md) {
      flex-shrink: 0;
      width: math.percentage(math.div(702, 1020));
    }
    @include mq(md) {
      display: block;
    }
  }
  &_img {
    @include mq(min-md) {
      flex: auto;
    }
    @include mq(md) {
      width: math.percentage(math.div(182, 279));
      margin-left: auto;
    }
  }
}

.dl-company {
  width: 100%;
  line-height: 1.38;
  @include mq(min-md) {
    display: grid;
    grid-template-columns: 11em 1fr;
    gap: 1.6em 1em;
  }
  dt {
    font-weight: bold;
  }
  dd {
    margin-left: 0;
    @include mq(md) {
      margin-top: 0.7em;
      + dt {
        margin-top: 1.8em;
      }
    }
  }
}

.list-3column {
  padding-left: 0;
  list-style: none;
  @include mq(min-md) {
    column-gap: 5%;
    column-count: 3;
  }
  li {
    &.-other {
      margin-top: 1.32em;
    }
  }
}

.list-inline {
  display: flex;
  gap: 0.2em 2em;
  padding-left: 0;
  list-style: none;
  @include mq(min-md) {
    flex-wrap: wrap;
  }
  @include mq(md) {
    flex-direction: column;
    li {
      &.-other {
        margin-top: 1.32em;
      }
    }
  }
}

.access {
  display: flex;
  @include mq(min-lg) {
    flex-direction: row-reverse;
    gap: 5%;
    justify-content: space-between;
  }
  @include mq(lg) {
    flex-direction: column;
    gap: 1em;
  }
  &_map {
    @include mq(min-lg) {
      flex-shrink: 0;
      width: math.percentage(math.div(470, 1020));
    }
    @include mq(lg) {
      width: 100%;
    }
  }
  &_mapFrame {
    width: 100%;
    @include mq(min-lg) {
      height: 441px;
    }
    @include mq(lg) {
      aspect-ratio: 279 / 262;
    }
    iframe {
      width: 100%;
      max-width: 100%;
      height: 100%;
    }
  }
  &_dl {
    margin-top: 0.8em;
    text-align: right;
  }
}

.dl-telList {
  display: grid;
  grid-template-columns: calc(140em / 16) 1fr;
  gap: 8px 16px;
  .ttl-content2 + & {
    margin-top: 0.5em;
  }
  dt {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 1.2;
    color: $color-dark;
    text-align: center;
    background-color: #f4f4f4;
  }
  dd {
    align-self: center;
    margin-left: 0;
  }
}

.dl-dotList {
  line-height: 1.4;
  @include mq(min-md) {
    display: grid;
    grid-template-columns: auto minmax(auto, 40%);
    grid-template-columns: auto auto;
    gap: 0.2em 1.2em;
    width: fit-content;
  }
  .ttl-content2 + & {
    margin-top: 0.5em;
  }
  dt {
    position: relative;
    padding-left: 1em;
    &::before {
      position: absolute;
      left: 0;
      content: "・";
    }
  }
  dd {
    margin-left: 0;
    @include mq(md) {
      padding-left: 1em;
      + dt {
        margin-top: 0.7em;
      }
    }
  }
}
