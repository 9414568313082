@use "sass:map";

// Colors
$color-text-base: #333333;
$color-bg-base: #f4f4f4;
$color-bg-blue: #e1e5eb;
$color-text-link: #0a55aa;
$color-accent: #0a55aa;
$color-dark: #00274d;
$color-real: #be741f;
$color-web: #14a0de;
$color-workshop: #38950f;

// Contents Width
$contentsWidth: 1100px;
$contentsPadding: 50px;

// Breakpoints
$mq: (
  "xs": 375px,
  "sm": 480px,
  "md": 768px,
  "hd": 965px,
  "lg": 1024px,
  "xl": 1366px,
);

// Fonts
$font-base: "メイリオ", Meiryo, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, "游ゴシック", "Yu Gothic", YuGothic, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;

@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
$font-en: "Bebas Neue", sans-serif;

// $font-code: Menlo, Monaco, "Courier New", monospace;
$font-code: "BIZ UDゴシック", "Source Han Code JP", monospace;

// ▼ Noto Sans（weightは必要なものだけに変更）
// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');
// $font-base: 'Noto Sans JP', 'Yu Gothic', YuGothic, 'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;

// ▼ 約物半角
// カッコのみ “〈〉《》「」『』【】〔〕（）［］｛｝”
// @import url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/css/yakuhanjp_s-noto.min.css");
// $font-yakuHan: YakuHanJPs_Noto, sans-serif;
// 約物すべて “、。！？〈〉《》「」『』【】〔〕・（）：；［］｛｝”
// @import url('https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/css/yakuhanjp-noto.min.css');
// $font-yakuHan: YakuHanJP_Noto, sans-serif;
