@use "sass:math";
@use "variables" as *;

// Media Queries
@mixin mq($canvas: md) {
  @each $size, $value in $mq {
    @if $canvas == min-#{$size} {
      @media not screen and (max-width: $value) {
        @content;
      }
    }
    @if $canvas == $size {
      @media only screen and (max-width: $value) {
        @content;
      }
    }
  }
}

// コンテンツ幅と余白を設定して中央寄せ
@mixin containerLayout() {
  max-width: $contentsWidth + $contentsPadding * 2;
  padding-right: $contentsPadding;
  padding-left: $contentsPadding;
  margin-right: auto;
  margin-left: auto;
  @include mq(md) {
    padding-right: math.percentage(math.div(24, 375));
    padding-left: math.percentage(math.div(24, 375));
  }
}

// 親要素のタグを指定
@mixin unify-parent($child) {
  @at-root #{selector-unify(&, $child)} {
    @content;
  }
}

// テキスト揃え
%textAlign {
  &.-center {
    text-align: center;
  }
  &.-left {
    text-align: left;
  }
  &.-right {
    text-align: right;
  }
}

// 点線（疑似要素などに充てて、親要素にrelative）
%dotLine {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  content: "";
  background: linear-gradient(to right, #ddd 2px, transparent 2px);
  background-repeat: repeat-x;
  background-position: left bottom;
  background-size: 6px 1px;
}

// リンク用アローアイコン（リンク要素に充てて、relative）
%circleArrow {
  &::before,
  &::after {
    position: absolute;
    color: $color-accent;
    content: "";
    transition: background-color 0.5s;
  }
  &::before {
    top: 0;
    right: 14px;
    bottom: 0;
    z-index: 1;
    width: 7px;
    height: 7px;
    // aspect-ratio: 1 / 1;
    margin: auto;
    border-color: currentColor;
    border-style: solid solid none none;
    border-width: 2px;
    transform: rotate(45deg);
  }
  &::after {
    top: 0;
    right: 0;
    bottom: 0;
    width: 32px;
    height: 32px;
    // aspect-ratio: 32 / 32;
    margin: auto;
    border: 1px solid currentColor;
    border-radius: 50%;
  }
  @media (hover: hover) {
    &:hover {
      border-color: $color-accent;
      &::before {
        color: #fff;
      }
      &::after {
        background-color: currentColor;
      }
    }
  }
}

// 紺背景の丸付数字
%circleNum {
  display: flex;
  flex: 0 0 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  // aspect-ratio: 1 / 1;
  font-family: $font-en;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  color: #fff;
  background-color: $color-dark;
  border-radius: 50%;
}

%seminarIcon {
  &::before {
    display: inline-block;
    content: "";
    background-color: #fff;
    background-image: none;
    transition: background-color 0.2s;
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center center;
  }
}
