@use "../abstracts" as *;
@use "sass:math";

.ttl-withEn {
  font-size: 24px;
  line-height: 1.5;
  text-align: center;
  + * {
    @include mq(min-md) {
      margin-top: 30px;
    }
    @include mq(md) {
      margin-top: 20px;
    }
  }
  &_ja {
    display: block;
    font-size: 12px;
    font-weight: bold;
  }
  &_en {
    display: block;
    font-family: $font-en;
    line-height: 1.2;
    letter-spacing: 0.04em;
    @include mq(min-md) {
      font-size: 40px;
    }
    @include mq(md) {
      font-size: 36px;
    }
    img {
      vertical-align: middle;
    }
  }
}

.ttl-content {
  font-weight: bold;
  line-height: 1.5;
  @include mq(min-md) {
    font-size: 24px;
  }
  @include mq(md) {
    font-size: 20px;
  }
  &.-center {
    text-align: center;
  }
  + * {
    @include mq(min-md) {
      margin-top: 30px;
    }
    @include mq(md) {
      margin-top: 20px;
    }
  }
}

.ttl-content2 {
  font-weight: bold;
  line-height: 1.5;
  @include mq(min-md) {
    margin-top: 2em;
    font-size: 18px;
  }
  @include mq(md) {
    margin-top: 1em;
    font-size: 16px;
  }
  &:first-child {
    margin-top: 0;
  }
  + * {
    margin-top: 15px;
  }
  + p {
    margin-top: 0.4em;
  }
}

.ttl-block {
  margin-top: 35px;
  font-weight: bold;
  line-height: 1.5;
  color: #fff;
  background-color: $color-accent;
  &:first-child {
    margin-top: 0;
  }
  @include mq(min-md) {
    padding: 0.5em calc(16em / 20);
    font-size: 20px;
  }
  @include mq(md) {
    padding: 0.5em calc(11em / 18);
    font-size: 18px;
  }
  + * {
    margin-top: 24px;
  }
  i {
    position: relative;
    display: inline-block;
    width: 56px;
    margin-right: 0.5em;
    margin-bottom: -0.2em;
    img {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}

.lead-contet {
  color: $color-dark;
  @include mq(min-md) {
    font-size: 18px;
    line-height: 1.67;
  }
  @include mq(md) {
    font-size: 16px;
    line-height: 1.63;
  }
}

.btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @include mq(min-md) {
    gap: 2em;
    margin-top: 40px;
  }
  @include mq(md) {
    gap: 1.6em;
    margin-top: 32px;
  }
  &:first-child {
    margin-top: 0;
  }
  button,
  a {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: calc(280em / 16);
    max-width: 100%;
    height: 50px;
    padding: 0.2em 1.7em 0.2em 1.5em;
    font-weight: bold;
    line-height: 1.3;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    background-color: $color-accent;
    border-style: none;
    border-radius: 50px;
    transition: background-color 0.2s, color 0.2s;
    @media (hover: hover) {
      &:hover {
        background-color: #6c99cc;
      }
    }
    &::after {
      position: absolute;
      top: 0;
      right: calc(16em / 16);
      bottom: 0;
      width: calc(8em / 16);
      height: calc(8em / 16);
      // aspect-ratio: 1 / 1;
      margin: auto;
      content: "";
      border-color: currentColor;
      border-style: solid solid none none;
      border-width: 2.5px;
      border-width: calc(2.5em / 16);
      transform: rotate(45deg);
    }
  }
  &_note {
    margin-top: 0.8em;
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
  }
  &.-dark button,
  button.-dark,
  &.-dark a,
  a.-dark {
    background-color: $color-dark;
    @media (hover: hover) {
      &:hover {
        background-color: #687c93;
      }
    }
  }
  &.-white button,
  button.-white,
  &.-white a,
  a.-white {
    color: $color-dark;
    background-color: #fff;
    border: 1px solid $color-dark;
    @media (hover: hover) {
      &:hover {
        background-color: #edeff3;
      }
    }
  }
  &.-transparent a,
  a.-transparent {
    color: $color-dark;
    background-color: #fff;
    @media (hover: hover) {
      &:hover {
        color: #fff;
        background-color: rgba(#fff, 0.4);
      }
    }
  }
  &.-small a,
  a.-small,
  &.-small button,
  button.-small {
    min-width: calc(160em / 14);
    height: 40px;
    font-size: 14px;
  }
  &.-download a,
  a.-download {
    padding-right: 2em;
    padding-left: 3.25em;
    &::before {
      position: absolute;
      left: 1.5em;
      width: 1em;
      height: 1em;
      // aspect-ratio: 1 / 1;
      content: "";
      background-color: #fff;
      transition: background-color 0.2s;
      mask-image: url("/assets/images/common/icon-download.svg");
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-position: center center;
    }
    &::after {
      content: none;
    }
  }
  &.-back button,
  button.-back,
  &.-back a,
  a.-back {
    &::after {
      right: auto;
      left: 1em;
      transform: rotate(225deg);
    }
  }
  &.-left {
    justify-content: flex-start;
  }
}

.link-anchor {
  position: relative;
  &::after {
    display: inline-block;
    width: 7px;
    height: 7px;
    // aspect-ratio: 1 / 1;
    margin-left: 0.6em;
    vertical-align: middle;
    vertical-align: 0.3em;
    content: "";
    border-color: currentColor;
    border-style: solid solid none none;
    border-width: 2px;
    transform: rotate(135deg);
  }
  &[href="#"] {
    &::after {
      vertical-align: 0.1em;
      transform: rotate(-45deg);
    }
  }
}

.link-access {
  &::before {
    display: inline-block;
    width: 20px;
    height: 20px;
    // aspect-ratio: 1 / 1;
    margin-right: 0.3em;
    vertical-align: middle;
    content: "";
    background-color: currentColor;
    transition: background-color 0.2s;
    mask-image: url("/assets/images/common/icon-access.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center center;
  }
}

.panel {
  line-height: 1.63;
  background-color: #fff;
  @include mq(min-md) {
    padding: math.percentage(math.div(56, 1100));
  }
  @include mq(md) {
    padding: 40px math.percentage(math.div(24, 327));
  }
  & + & {
    margin-top: 40px;
  }
}

.list-note {
  $this: &;
  font-size: 12px;
  + * {
    @include mq(min-md) {
      margin-top: 50px;
    }
    @include mq(md) {
      margin-top: 30px;
    }
  }
  @at-root {
    ul {
      &#{$this} {
        padding-left: 0px;
        margin-left: 0px;
        list-style-type: none;
        &.-dot {
          > li {
            &::before {
              content: "・";
            }
          }
        }
      }
    }
    p#{$this},
    ul#{$this} > li,
    ol#{$this} > li {
      position: relative;
      padding-left: 1.2em;
      padding-left: calc(16em / 12);
      + li {
        margin-top: 0.3em;
      }
      &::before {
        position: absolute;
        left: 0;
        content: "※";
      }
    }
    ol#{$this} {
      counter-reset: number;
      > li {
        padding-left: 2.5em;
        counter-increment: number;
        &::before {
          content: "※" counter(number);
        }
      }
      @for $i from 0 through 9 {
        &.-counter-#{$i + 1} {
          counter-reset: number #{$i};
        }
      }
    }
  }
}

.list-dot {
  padding-left: 0px;
  margin-left: 0px;
  line-height: 1.5;
  list-style-type: none;
  > li {
    position: relative;
    padding-left: 1em;
    + li {
      @include mq(min-md) {
        margin-top: 0.5em;
      }
      @include mq(md) {
        margin-top: 0.3em;
      }
    }
    &::before {
      position: absolute;
      left: 0;
      content: "・";
    }
  }
}

.list-check {
  display: grid;
  gap: 8px;
  padding-left: 0;
  list-style: none;
  &.-col {
    @include mq(min-md) {
      grid-template-columns: 1fr 1fr;
    }
    @include mq(md) {
      grid-template-columns: repeat(auto-fill, minmax(279px, 1fr));
    }
  }
  &_item {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 56px;
    line-height: 1.4;
    border: 4px solid $color-bg-blue;
    @include mq(min-md) {
      padding: 0.1em 1em 0.1em calc(53em / 16);
    }
    @include mq(md) {
      padding: 0.9em 1em 0.9em calc(53em / 16);
    }
    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      display: block;
      width: 26px;
      aspect-ratio: 26 / 26;
      margin: auto;
      content: "";
      background-color: $color-accent;
      mask-image: url("/assets/images/common/icon-check.svg");
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-position: center center;
      @include mq(min-md) {
        left: 1em;
      }
      @include mq(md) {
        left: 0.9em;
      }
    }
  }
}

.underLine {
  display: inline;
  padding-bottom: 0em;
  background: linear-gradient(transparent 65%, #edeff3 0%);
}

.tb-2column {
  width: 100%;
  line-height: 1.38;
  @include mq(md) {
    border-top: 1px solid #ddd;
  }
  + .list-note {
    @include mq(min-md) {
      margin-top: 25px;
    }
    @include mq(md) {
      margin-top: 15px;
    }
  }
  &,
  tbody,
  tr,
  th,
  td {
    @include mq(md) {
      display: block;
    }
  }
  thead,
  tbody {
    th,
    td {
      border: 1px solid #ddd;
      @include mq(min-md) {
        padding: 1em 1em;
      }
      @include mq(md) {
        width: auto;
        padding: 1em calc(12em / 15);
        border-top-style: none;
      }
    }
  }
  tbody {
    th {
      text-align: left;
      background-color: #f4f4f4;
      @include mq(min-md) {
        width: 280px;
      }
      @include mq(md) {
        width: auto;
      }
    }
    td {
    }
  }
}

.tb-price {
  width: 100%;
  line-height: 1.38;
  thead,
  tbody {
    th,
    td {
      border: 1px solid #ddd;
      @include mq(min-md) {
        padding: 1em 1em;
      }
      @include mq(md) {
        padding: 1em calc(12em / 15);
      }
      &.-center {
        &:nth-child(n) {
          text-align: center !important;
        }
      }
    }
  }
  thead {
    th {
      color: #fff;
      background-color: $color-dark;
    }
  }
  tbody {
    th {
      font-weight: normal;
      text-align: left;
    }
    td {
      text-align: center;
      &.-gray {
        background-color: #f4f4f4;
      }
      strong {
        .-num {
          font-size: calc(24em / 16);
        }
      }
      .-small {
        font-size: calc(12em / 16);
      }
    }
  }
  &.-patentscore {
    td {
      text-align: right;
    }
  }
  &.-delivery {
    thead {
      th {
        &:nth-last-child(-n + 2) {
          width: math.percentage(math.div(160, 720));
        }
      }
    }
    tbody {
      td {
        &.-gray {
          width: 32px;
          background-color: #e1e5eb;
          border-top-color: #e1e5eb;
        }
        &:nth-last-child(3) {
          width: math.percentage(math.div(180, 720));
        }
      }
    }
    tr {
      &.-gray {
        background-color: #e1e5eb;
        th {
          padding-left: 32px;
          border-right-color: #fff;
          border-bottom-style: none;
        }
        td {
          height: 108px;
          &:not(:last-child) {
            border-right-color: #fff;
          }
        }
      }
    }
  }
  &.-report {
    thead {
      th {
        &:nth-last-child(-n + 3) {
          width: math.percentage(math.div(148, 720));
        }
      }
    }
  }
}

.box-gray {
  background-color: #f4f4f4;
  @include mq(min-md) {
    padding: math.percentage(math.div(24, 720));
    margin-top: 2em;
  }
  @include mq(md) {
    padding: math.percentage(math.div(20, 279));
    margin-top: 1em;
  }
  &:first-child {
    margin-top: 0;
  }
}

.box-line {
  border: 1px solid #ddd;
  @include mq(min-md) {
    padding: 23px 23px;
    margin-top: 2em;
  }
  @include mq(md) {
    padding: math.percentage(math.div(20, 279));
    margin-top: 1em;
  }
  &:first-child {
    margin-top: 0;
  }
}

.nav-pageNav {
  position: relative;
  margin: 40px auto 0px;
  &_list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: fit-content;
    list-style: none;
    @include mq(min-md) {
      margin: 0 -5px -10px;
      margin: 0 auto -10px;
    }
    @include mq(md) {
      padding: 0 40px;
      margin: 0 auto -13px;
    }
  }
  &_numbers:not(.btn_outer) {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    height: 40px;
    padding: 0;
    font-weight: normal;
    color: $color-dark;
    text-decoration: none;
    text-indent: 0.1em;
    background-color: transparent;
    border: 1px solid $color-dark;
    border-radius: 30px;
    transition: background-color 0.3s;
    @include mq(min-md) {
      margin: 0 0.5em 1em;
    }
    @include mq(md) {
      margin: 0 6.5px 13px;
    }
    @media (hover: hover) {
      &:hover:not(.-dots) {
        color: #fff;
        background-color: $color-dark;
      }
    }
    &.-active {
      color: #fff;
      text-decoration: none;
      cursor: default;
      background-color: $color-dark;
    }
    &.-dots {
      min-width: auto;
      font-size: 1.2em;
      background-color: transparent;
      border-style: none;
      box-shadow: none;
      @include mq(md) {
        margin-right: 6px;
        margin-left: 6px;
      }
    }
    &.-first,
    &.-last,
    &.-prev,
    &.-next {
      background-color: transparent;
      @include mq(md) {
        position: absolute;
        top: calc(50% - 6.5px);
        margin: 0;
        transform: translateY(-50%);
      }
    }
    &.-prev,
    &.-first {
      &::before,
      &::after {
      }
    }
    &.-next,
    &.-last {
      &::before,
      &::after {
      }
    }
    &.-prev,
    &.-next {
      color: transparent;
      background-color: #4e4e4e;
      border-style: none;
      &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 10px;
        height: 10px;
        margin: auto;
        content: "";
        border-color: #fff;
        border-width: 1px;
        transition: left 0.2s, right 0.2s;
        transform: rotate(45deg);
      }
      &::after {
        content: none;
      }
    }
    &.-first,
    &.-last {
      min-width: 100px;
      &::before,
      &::after {
      }
    }
    &.-prev {
      margin-right: 20px;
      @include mq(md) {
        left: 0px;
        // right: calc(50% + 5px);
        margin-right: 0;
      }
      &::before {
        border-style: none none solid solid;
        transform: translateX(20%) rotate(45deg);
      }
      &:hover {
        &::before {
          right: 15%;
        }
      }
    }
    &.-first {
      @include mq(md) {
        right: calc(50% + 75px);
        margin-right: 0;
      }
      &::before {
      }
      &:hover {
        &::before {
        }
      }
    }
    &.-next {
      margin-left: 20px;
      @include mq(md) {
        right: 0px;
        // left: calc(50% + 5px);
        margin-left: 0;
      }
      &::before {
        border-style: solid solid none none;
        transform: translateX(-20%) rotate(45deg);
      }
      &::after {
      }
      &:hover {
        &::before {
          left: 15%;
        }
        &::after {
        }
      }
    }
    &.-last {
      @include mq(md) {
        left: calc(50% + 75px);
      }
      &::after {
      }
      &:hover {
        &::after {
        }
      }
    }
  }
}

.modal {
  display: none;
  &.is-open {
    display: block;
  }
  &_overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.6);
    [aria-hidden="false"] & {
      overflow-y: scroll;
      animation: fadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
    [aria-hidden="true"] & {
      animation: fadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
    @include mq(min-md) {
      padding: math.percentage(math.div(90, 1366));
    }
    @include mq(md) {
      padding: 59px math.percentage(math.div(24, 375)) 42px;
    }
  }
  &_container {
    position: relative;
    max-width: 1100px;
    max-height: 100%;
    margin: 0 auto;
    background-color: #fff;
    @include mq(min-md) {
      padding: 56px;
    }
    @include mq(md) {
      padding: 56px math.percentage(math.div(20, 327)) 40px;
    }
  }
  &_close {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 30;
    cursor: pointer;
    background-color: #fff;
    border: 2px solid $color-accent;
    border-radius: 50%;
    @include mq(min-md) {
      width: 56px;
      height: 56px;
      transform: translate(50%, -50%);
    }
    @include mq(md) {
      width: 48px;
      height: 48px;
      transform: translateY(-50%);
    }
    &::before,
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 25px;
      width: math.percentage(math.div(25, 56));
      height: 2px;
      content: "";
      background: $color-accent;
    }
    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
  &_content {
    height: 100%;
  }
  #modalImage {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }
}

.tabPanel {
  display: none;
  &.-active {
    display: block;
    animation: fadeIn 1.2s ease-out 0.3s both;
  }
}

.tb-scroll {
  @include mq(md) {
    display: flex;
    padding-bottom: 8px;
    margin-right: math.percentage(math.div(-24, 279));
    margin-left: math.percentage(math.div(-24, 279));
    overflow-x: scroll;
    &::-webkit-scrollbar {
      height: 3px;
      margin-top: 0.5em;
    }
    &::-webkit-scrollbar-track {
      margin: 0 calc(24vw / 375 * 100);
      background: #faf9f9;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: #ddd;
      border-radius: 5px;
    }
    &::before,
    &::after {
      display: block;
      flex-shrink: 0;
      width: math.percentage(math.div(24, 327));
      content: "";
    }
    table {
      // width: 800px;
      min-width: 600px;
    }
  }
}
