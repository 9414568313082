@use "../abstracts" as *;
@use "../components/components";
@use "sass:math";

.nav-newsMedia {
  &_list {
    display: flex;
    gap: 4px;
    padding-left: 0;
    overflow: hidden;
    list-style: none;
    border-radius: 59px;
  }
  &_item {
    flex: 1;
  }
  &_link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: $color-text-base;
    text-decoration: none;
    background-color: #ddd;
    transition: background-color 0.2s;
    @include mq(min-md) {
      min-height: 59px;
      font-size: 18px;
    }
    @include mq(md) {
      min-height: 56px;
      font-size: 16px;
    }
    @media (hover: hover) {
      &:hover {
        color: #fff;
        background-color: $color-accent;
      }
    }
    &.-active {
      color: #fff;
      background-color: $color-accent;
    }
  }
}

.nav-archive {
  overflow: hidden;
  @include mq(min-md) {
    margin-top: 40px;
  }
  @include mq(md) {
    margin-top: 24px;
    overflow: hidden;
    border: 1px solid $color-dark;
    border-radius: 24px;
  }
  &_select {
    @include mq(min-md) {
      display: none;
    }
    @include mq(md) {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 47px;
      cursor: pointer;
      background-color: #f4f4f4;
      &::after {
        position: absolute;
        top: 0;
        right: 1em;
        bottom: 0;
        width: 12px;
        height: 8px;
        margin: auto;
        clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
        content: "";
        background-color: currentColor;
      }
      &.-open {
        &::after {
          transform: scaleY(-1);
        }
      }
      .-toggle {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include mq(min-md) {
          display: none;
        }
        @include mq(md) {
          display: block;
        }
      }
    }
  }
  &_list {
    padding-left: 0;
    margin: 0;
    list-style: none;
    @include mq(min-md) {
      display: flex !important;
      flex-wrap: wrap;
      gap: 1em;
    }
    @include mq(md) {
      display: none;
    }
  }
  &_item {
    // flex: auto;
  }
  &_link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.2em 30px;
    color: $color-dark;
    text-decoration: none;
    border: 1px solid $color-dark;
    transition: background-color 0.2s;
    @include mq(min-md) {
      min-height: 40px;
      border-radius: 40px;
    }
    @include mq(md) {
      min-height: 47px;
      border-style: solid none none;
    }
    @media (hover: hover) {
      &:hover {
        color: #fff;
        background-color: $color-dark;
      }
    }
    .nav-archive_active & {
      color: #fff;
      background-color: $color-dark;
    }
    &.-active {
      color: #fff;
      background-color: $color-dark;
    }
  }
}

.newsMedia {
  margin-top: 40px;
  @include mq(min-md) {
    padding-top: math.percentage(math.div(40, 1100));
  }
  @include mq(md) {
    padding-top: math.percentage(math.div(22, 327));
    padding-bottom: 40px;
  }
}

.sec-postHeader {
  @include mq(min-md) {
    padding-bottom: 50px;
  }
  @include mq(md) {
    padding-top: 40px;
    padding-bottom: 32px;
  }
}

.postHeader {
  @include mq(md) {
    position: relative;
    padding-bottom: calc(26px + 16px);
  }
  &_ttl {
    font-weight: bold;
    line-height: 1.5;
    @include mq(min-md) {
      font-size: 36px;
    }
    @include mq(md) {
      font-size: 28px;
    }
  }
  &_info {
    display: flex;
    align-items: center;
    + * {
      margin-top: 12px;
    }
  }
  &_tag {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 64px;
    height: 26px;
    padding: 0.1em 8px;
    font-size: 12px;
    color: $color-dark;
    background-color: #fff;
  }
  &_date {
    margin: 0 0 0 auto;
    font-size: 12px;
  }
  &_sns {
    margin: 0 0 0 1em;
    line-height: 1;
    @include mq(md) {
      position: absolute;
      right: 0;
      bottom: 0;
    }
    a {
      display: flex;
      gap: 0.5em;
      align-items: center;
      justify-content: center;
      min-width: 108px;
      height: 28px;
      font-size: 12px;
      font-weight: bold;
      line-height: 1;
      color: #fff;
      text-decoration: none;
      background-color: #333;
      border-radius: 26px;
      &::before {
        width: 1em;
        height: 1em;
        content: "";
        background-color: #fff;
        mask-image: url("/assets/images/common/icon-x.svg");
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center center;
      }
    }
  }
  section.-words & {
    @include mq(md) {
      padding-bottom: 0;
    }
  }
  .list-article_industries {
    @include mq(min-md) {
      font-size: 16px;
    }
    @include mq(md) {
      font-size: 15px;
    }
  }
}

.sec-postDetails {
  padding-top: 0;
}

.postDetails {
  @include mq(min-md) {
    padding: math.percentage(math.div(40, 1100));
  }
  @include mq(md) {
    padding: 32px math.percentage(math.div(24, 327));
  }
  h3 {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.5;
    + * {
      margin-top: 0.3em;
    }
  }
  center {
    &:has(table.ranking) {
      @include mq(md) {
        text-align: left;
      }
    }
  }
  table.ranking {
    @extend .tb-price;
    width: auto;
    min-width: 80%;
    margin-top: 1em;
    tr {
      th {
        color: #fff;
        text-align: center;
        white-space: normal;
        background-color: $color-dark;
      }
      td {
        text-align: right;
        white-space: normal;
      }
    }
    &.-w60 {
      min-width: 60%;
    }
    &.-w40 {
      min-width: 40%;
    }
  }
  &_annotation {
    margin-top: 1em;
    font-size: 12px;
  }
  .post-column {
    display: flex;
    margin-top: 2em;
    @include mq(min-md) {
      gap: 3em;
    }
    @include mq(md) {
      flex-direction: column;
      gap: 1.5em;
    }
    &_img {
      flex: 1 0 auto;
      max-width: 100%;
      text-align: center;
    }
  }
}
