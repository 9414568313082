@use "../abstracts" as *;
@use "../components/components";
@use "sass:math";

.sec-seminarFilter {
  background-color: #e1e5eb;
  @include mq(min-md) {
    min-height: 172px;
    padding: 40px 0;
  }
  @include mq(md) {
    padding: 32px 0;
  }
}

.seminarFilter {
  &_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &_body {
    margin-top: 20px;
  }
  &_ttl {
    font-weight: bold;
    line-height: 1.5;
    @include mq(min-md) {
      font-size: 18px;
    }
    @include mq(md) {
      font-size: 16px;
    }
  }
  &_reset {
    margin: 0;
    color: $color-accent;
    text-decoration: underline;
    cursor: pointer;
    @media (hover: hover) {
      &:hover {
        text-decoration: none;
      }
    }
  }
  &_list {
    padding-left: 0;
    list-style: none;
    @include mq(min-md) {
      display: flex;
      flex-wrap: wrap;
      gap: 1em;
    }
    @include mq(md) {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
      gap: 15px;
    }
  }
  &_item {
    opacity: 0;
    animation: fadeIn 0.5s ease-out 0.2s both;
  }
  &_link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    line-height: 1.2;
    color: $color-accent;
    text-decoration: none;
    cursor: pointer;
    background-color: #fff;
    border-radius: 40px;
    transition: background-color 0.2s;
    @include mq(min-md) {
      padding: 0.2em 30px;
    }
    @include mq(md) {
      padding: 0.2em 0.3em;
    }
    &.-active {
      color: #fff;
      background-color: $color-accent;
    }
  }
}

.sec-seminarList {
  padding-top: 40px;
  .sec-pageHeader + & {
    padding-top: 0;
  }
}

.sec-seminarCate {
  padding-bottom: 40px;
}

.nav-seminarCate {
  $this: &;
  @include mq(md) {
    overflow: hidden;
    border-radius: 28px;
  }
  &_select {
    @include mq(min-md) {
      display: none;
    }
    @include mq(md) {
      position: relative;
      display: block;
      &::after {
        position: absolute;
        top: 0;
        right: 1em;
        bottom: 0;
        width: 12px;
        height: 8px;
        margin: auto;
        clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
        content: "";
        background-color: #fff;
      }
      &.-open {
        &::after {
          transform: scaleY(-1);
        }
      }
    }
  }
  &_list {
    padding-left: 0;
    list-style: none;
    @include mq(min-md) {
      display: flex;
      gap: 4px;
      overflow: hidden;
      border-radius: 59px;
    }
    @include mq(md) {
      // display: none;
      margin-top: 0;
    }
  }
  &_item {
    @include mq(min-md) {
      flex: 1;
    }
    @include mq(md) {
      + li {
        margin-top: 4px;
      }
    }
    &.-real,
    &.-web,
    &.-workshop {
      #{$this}_link {
        &::before {
          display: inline-block;
          content: "";
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
        }
        @media (hover: hover) {
          &:hover {
            &::before {
              display: inline-block;
              content: "";
              background-color: #fff;
              background-image: none;
              transition: background-color 0.2s;
              mask-size: contain;
              mask-repeat: no-repeat;
              mask-position: center center;
            }
          }
        }
        &.-active {
          &::before {
            display: inline-block;
            content: "";
            background-color: #fff;
            background-image: none;
            transition: background-color 0.2s;
            mask-size: contain;
            mask-repeat: no-repeat;
            mask-position: center center;
          }
        }
      }
    }
    &.-real {
      #{$this}_link {
        &::before {
          width: 41px;
          height: 32px;
          background-image: url("/assets/images/common/icon-seminar-real.svg");
        }
        @media (hover: hover) {
          &:hover {
            &::before {
              mask-image: url("/assets/images/common/icon-seminar-real.svg");
            }
          }
        }
        &.-active {
          &::before {
            mask-image: url("/assets/images/common/icon-seminar-real.svg");
          }
        }
      }
    }
    &.-web {
      #{$this}_link {
        &::before {
          width: 26px;
          height: 32px;
          background-image: url("/assets/images/common/icon-seminar-web.svg");
        }
        @media (hover: hover) {
          &:hover {
            &::before {
              mask-image: url("/assets/images/common/icon-seminar-web.svg");
            }
          }
        }
        &.-active {
          &::before {
            mask-image: url("/assets/images/common/icon-seminar-web.svg");
          }
        }
      }
    }
    &.-workshop {
      #{$this}_link {
        &::before {
          width: 34px;
          height: 36px;
          background-image: url("/assets/images/common/icon-seminar-workshop.svg");
        }
        @media (hover: hover) {
          &:hover {
            &::before {
              mask-image: url("/assets/images/common/icon-seminar-workshop.svg");
            }
          }
        }
        &.-active {
          &::before {
            mask-image: url("/assets/images/common/icon-seminar-workshop.svg");
          }
        }
      }
    }
  }
  &_select,
  &_link {
    display: flex;
    gap: calc(8em / 18);
    align-items: center;
    justify-content: center;
    height: 100%;
    font-weight: bold;
    line-height: 1.2;
    color: $color-text-base;
    text-decoration: none;
    background-color: #ddd;
    transition: background-color 0.2s;
    @include mq(min-md) {
      min-height: 59px;
      font-size: 14px;
    }
    @include mq(min-lg) {
      font-size: 18px;
    }
    @include mq(md) {
      min-height: 56px;
      font-size: 16px;
    }
    @media (hover: hover) {
      &:hover {
        color: #fff;
        background-color: $color-accent;
      }
    }
    &.-active {
      color: #fff;
      background-color: $color-accent;
    }
    i {
      display: inline-block;
      font-size: 12px;
    }
  }
}

.seminarList {
  $this: &;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-left: 0;
  list-style: none;
  &_noResults {
    display: none;
    line-height: 1.5;
    @include mq(min-md) {
      margin: 32px 0 60px;
      text-align: center;
    }
    @include mq(md) {
      padding: 0 math.percentage(math.div(24, 327));
      margin-bottom: 56px;
      text-align: left;
    }
  }
  &_item {
    position: relative;
    &.-web {
      #{$this}_link {
        &::after {
          background-color: $color-web;
        }
        @media (hover: hover) {
          &:hover {
            border-color: $color-web;
          }
        }
      }
      #{$this}_tag {
        background-color: $color-web;
      }
      #{$this}_head {
        @include mq(min-md) {
          padding-left: 38px;
        }
        @include mq(md) {
          padding-left: 0;
        }
        &::before {
          width: 26px;
          background-image: url("/assets/images/common/icon-seminar-web.svg");
        }
      }
      #{$this}_target {
        color: $color-web;
        @include mq(md) {
          padding-left: 34px;
          &::before {
            width: 26px;
            background-image: url("/assets/images/common/icon-seminar-web.svg");
          }
        }
      }
      #{$this}_info {
        dt {
          background-color: mix($color-accent, #fff, 13%);
        }
      }
    }
    &.-real {
      #{$this}_link {
        &::after {
          background-color: $color-real;
        }
        @media (hover: hover) {
          &:hover {
            border-color: $color-real;
          }
        }
      }
      #{$this}_tag {
        background-color: $color-real;
      }
      #{$this}_head {
        @include mq(min-md) {
          padding-left: 53px;
        }
        @include mq(md) {
          padding-left: 0;
        }
        &::before {
          width: 41px;
          background-image: url("/assets/images/common/icon-seminar-real.svg");
        }
      }
      #{$this}_target {
        color: $color-real;
        @include mq(md) {
          padding-left: 49px;
          &::before {
            width: 41px;
            background-image: url("/assets/images/common/icon-seminar-real.svg");
          }
        }
      }
      #{$this}_info {
        dt {
          background-color: mix($color-real, #fff, 20%);
        }
      }
    }
    &.-workshop {
      #{$this}_link {
        &::after {
          background-color: $color-workshop;
        }
        @media (hover: hover) {
          &:hover {
            border-color: $color-workshop;
          }
        }
      }
      #{$this}_tag {
        background-color: $color-workshop;
      }
      #{$this}_head {
        @include mq(min-md) {
          padding-left: 53px;
        }
        @include mq(md) {
          padding-left: 0;
        }
        &::before {
          width: 41px;
          background-image: url("/assets/images/common/icon-seminar-workshop.svg");
        }
      }
      #{$this}_target {
        color: $color-workshop;
        @include mq(md) {
          padding-left: 49px;
          &::before {
            width: 41px;
            background-image: url("/assets/images/common/icon-seminar-workshop.svg");
          }
        }
      }
      #{$this}_info {
        dt {
          background-color: mix($color-workshop, #fff, 10.5%);
        }
      }
    }
    display: none;
    &.-show {
      display: block;
    }
  }
  &_link {
    display: block;
    text-decoration: none;
    background-color: #fff;
    border: 2px solid #fff;
    transition: border-color 0.3s;
    @include mq(min-md) {
      padding: math.percentage(math.div(38, 1100));
    }
    @include mq(md) {
      padding: 46px math.percentage(math.div(24, 327)) math.percentage(math.div(24, 327));
    }
    @media (hover: hover) {
      &:hover {
        border-color: $color-accent;
      }
    }
    &::after {
      position: absolute;
      right: 1em;
      bottom: 1em;
      display: block;
      width: 24px;
      aspect-ratio: 1 / 1;
      clip-path: polygon(100% 0%, 100% 100%, 0% 100%);
      content: "";
      background-color: $color-accent;
    }
  }
  &_tag {
    position: absolute;
    top: 0;
    left: 0;
    padding: 6px 8px;
    font-size: 12px;
    line-height: 1.2;
    color: #fff;
    background-color: $color-accent;
  }
  &_head {
    @include mq(min-md) {
      position: relative;
    }
    @include mq(md) {
      display: flex;
      flex-direction: column-reverse;
      gap: 12px;
    }
    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      display: block;
      height: 32px;
      margin: auto;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      @include mq(min-md) {
        content: "";
      }
      @include mq(md) {
        content: none;
      }
    }
  }
  &_ttl {
    font-weight: bold;
    line-height: 1.5;
    color: $color-text-base;
    @include mq(min-md) {
      font-size: 20px;
    }
    @include mq(md) {
      font-size: 18px;
    }
  }
  &_target {
    font-size: 14px;
    line-height: 1.4;
    color: $color-accent;
    @include mq(min-md) {
      margin-top: 0.2em;
    }
    @include mq(md) {
      position: relative;
      display: flex;
      align-items: center;
      min-height: 32px;
      margin-top: 0;
      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        display: block;
        height: 32px;
        margin: auto;
        content: "";
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }
    }
  }
  &_info {
    display: grid;
    gap: 1em;
    align-items: center;
    line-height: 1.5;
    color: $color-text-base;
    @include mq(min-md) {
      grid-template-columns: 6em 448fr 6em 332fr;
    }
    @include mq(md) {
      grid-template-columns: 92px 1fr;
    }
    dt {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 32px;
      background-color: mix($color-accent, #fff, 20%);
      @include mq(min-md) {
      }
      @include mq(md) {
        align-self: flex-start;
      }
    }
    dd {
      margin-left: 0;
    }
    .-past & {
      @include mq(min-md) {
        grid-template-columns: 6em 1fr;
      }
    }
  }
}

.noPosts {
  padding-bottom: 60px;
  text-align: center;
  &_img {
    width: 167px;
    margin: 0 auto;
    @include mq(md) {
      max-width: math.percentage(math.div(143, 327));
    }
  }
  &_ttl {
    font-weight: bold;
    line-height: 1.5;
    @include mq(min-md) {
      margin-top: 40px;
      font-size: 32px;
    }
    @include mq(md) {
      margin-top: 30px;
      font-size: 24px;
    }
  }
  &_note {
    margin-top: 0.4em;
    line-height: 1.5;
  }
}

.sec-seminarHeader {
  @include mq(min-md) {
    padding-bottom: 50px;
  }
  @include mq(md) {
    padding-top: 40px;
    padding-bottom: 32px;
  }
}

.seminarHeader {
  $this: &;
  @include mq(md) {
    position: relative;
  }
  section.-real & {
    #{$this}_tag {
      background-color: $color-real;
    }
    #{$this}_target {
      color: $color-real;
      &::before {
        background-image: url("/assets/images/common/icon-seminar-real.svg");
      }
    }
  }
  section.-web & {
    #{$this}_tag {
      background-color: $color-web;
    }
    #{$this}_target {
      color: $color-web;
      &::before {
        background-image: url("/assets/images/common/icon-seminar-web.svg");
      }
    }
  }
  section.-workshop & {
    #{$this}_tag {
      background-color: $color-workshop;
    }
    #{$this}_target {
      color: $color-workshop;
      &::before {
        background-image: url("/assets/images/common/icon-seminar-workshop.svg");
      }
    }
  }
  &_body {
    @include mq(min-md) {
      position: relative;
      padding-left: 72px;
    }
    @include mq(md) {
      display: flex;
      flex-direction: column-reverse;
      padding-left: 0;
    }
  }
  &_ttl {
    font-weight: bold;
    line-height: 1.5;
    @include mq(min-md) {
      font-size: 36px;
    }
    @include mq(md) {
      margin-top: 0.2em;
      font-size: 28px;
    }
  }
  &_target {
    line-height: 1.5;
    color: $color-text-base;
    @include mq(min-md) {
      margin-top: 0.2em;
    }
    @include mq(md) {
      position: relative;
      display: flex;
      gap: 17px;
      align-items: center;
      margin-top: 0;
    }
    &::before {
      display: block;
      width: 55px;
      height: 40px;
      content: "";
      background-image: url("/assets/images/common/icon-seminar-web.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      @include mq(min-md) {
        position: absolute;
        top: 7px;
        left: 0;
      }
      @include mq(md) {
        position: static;
      }
    }
  }
  &_info {
    display: flex;
    align-items: center;
    + * {
      margin-top: 12px;
    }
  }
  &_tag {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 64px;
    height: 26px;
    padding: 0.2em 8px;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    color: $color-dark;
    color: #fff;
    background-color: $color-text-base;
  }
  &_date {
    margin: 0 0 0 auto;
    font-size: 12px;
  }
  &_sns {
    margin: 0 0 0 1em;
    @include mq(md) {
      position: absolute;
      right: 0;
      bottom: 0;
    }
    a {
      display: flex;
      gap: 0.5em;
      align-items: center;
      justify-content: center;
      min-width: 108px;
      height: 26px;
      font-size: 12px;
      font-weight: bold;
      line-height: 1;
      color: #fff;
      text-decoration: none;
      background-color: #333;
      border-radius: 26px;
      &::before {
        width: 1em;
        height: 1em;
        content: "";
        background-color: #fff;
        mask-image: url("/assets/images/common/icon-x.svg");
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center center;
      }
    }
  }
}

.seminarInfo {
  line-height: 1.5;
  color: #c31616;
  list-style: none;
  background-color: #f8e7e7;
  @include mq(min-md) {
    padding: 24px;
    margin-top: 40px;
    font-size: 18px;
  }
  @include mq(md) {
    padding: 1em;
    margin-top: 24px;
    font-size: 16px;
  }
  &_item {
    position: relative;
    padding-left: 32px;
    &::before {
      position: absolute;
      top: 0.2em;
      left: 0;
      display: inline-block;
      width: 20px;
      height: 20px;
      // aspect-ratio: 1 / 1;
      content: "";
      background-image: url("/assets/images/common/icon-information.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }
  }
}

.sec-seminarDetails {
  padding-top: 0;
  p {
    max-width: 100%;
  }
  .panel {
    @include mq(min-md) {
      padding: math.percentage(math.div(40, 1100));
    }
    @include mq(md) {
      padding: 32px math.percentage(math.div(24, 327));
    }
    + .panel {
      @include mq(min-md) {
        margin-top: 40px;
      }
      @include mq(md) {
        margin-top: 24px;
      }
    }
  }
  .ttl-content {
    + p {
      margin-top: 0.8em;
    }
  }
  .list-check {
    margin-top: 20px;
    @include mq(md) {
      gap: 16px;
    }
    &_item {
      min-height: 72px;
      font-weight: bold;
      border-color: mix($color-text-base, #fff, 20%);
      @include mq(min-md) {
        padding-left: 58px;
      }
      @include mq(md) {
        padding-left: 50px;
      }
      &::before {
        background-color: $color-text-base;
        @include mq(min-md) {
          left: 20px;
        }
        @include mq(md) {
          left: 12px;
        }
      }
    }
  }
  &.-real {
    .list-check {
      &_item {
        border-color: mix($color-real, #fff, 20%);
        &::before {
          background-color: $color-real;
        }
      }
    }
  }
  &.-web {
    .list-check {
      &_item {
        border-color: mix($color-web, #fff, 20%);
        &::before {
          background-color: $color-web;
        }
      }
    }
  }
  &.-workshop {
    .list-check {
      &_item {
        border-color: mix($color-workshop, #fff, 20%);
        &::before {
          background-color: $color-workshop;
        }
      }
    }
  }
}

.postForm {
  padding: math.percentage(math.div(40, 1020));
}

.link-seminarPast {
  margin-top: 40px;
  text-align: right;
}
