@use "../abstracts" as *;
@use "../components/components";
@use "sass:math";

.service {
  &_about {
    line-height: 1.63;
  }
  &_logic {
    &Img {
      margin-top: 2em;
    }
    &Bnr {
      margin-top: 2em;
      text-align: center;
      img {
        transition: opacity 0.2s;
        @include mq(md) {
          width: 279px;
        }
      }
      a {
        @media (hover: hover) {
          &:hover {
            img {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
  &_price {
    .btn {
      margin-top: 2em;
    }
  }
  &_inquiry {
    .formSet {
      @include mq(min-lg) {
        margin-top: 30px;
      }
      @include mq(lg) {
        margin-top: 20px;
      }
    }
  }
  &_product {
    .ttl-block {
      margin-top: 50px;
    }
  }
}

.sec-contentList {
  background-color: $color-bg-blue;
  @include mq(min-lg) {
    padding: 56px 0;
  }
  @include mq(lg) {
    padding: 40px 0;
  }
}

.nav-contentList {
  padding-left: 0;
  list-style: none;
  counter-reset: num 0;
  @include mq(min-lg) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    gap: 32px;
    line-height: 1.63;
  }
  @include mq(lg) {
    display: flex;
    flex-direction: column;
    gap: 16px;
    line-height: 1.47;
  }
  &_item {
    position: relative;
    overflow: hidden;
    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0.1em;
      width: 1.2em;
      height: 1em;
      margin: auto;
      font-family: $font-en;
      line-height: 1;
      color: #e5e5e5;
      text-align: center;
      pointer-events: none;
      content: "0" counter(num);
      counter-increment: num;
      @include mq(min-lg) {
        font-size: 80px;
      }
      @include mq(lg) {
        font-size: 64px;
      }
    }
  }
  &_link {
    display: block;
    height: 100%;
    color: $color-text-base;
    text-decoration: none;
    background-color: #fff;
    border: 2px solid #fff;
    border-radius: 24px;
    transition: border-color 0.3s;
    @include mq(min-lg) {
      padding: 30px 78px 30px 116px;
    }
    @include mq(lg) {
      padding: 22px 68px 22px 87px;
    }
    &::before,
    &::after {
      position: absolute;
      color: $color-accent;
      content: "";
      transition: background-color 0.5s;
    }
    &::before {
      top: 0;
      bottom: 0;
      z-index: 1;
      width: 7px;
      height: 7px;
      // aspect-ratio: 1 / 1;
      margin: auto;
      border-color: currentColor;
      border-style: solid solid none none;
      border-width: 2px;
      transform: rotate(135deg) translateY(5%);
      @include mq(min-lg) {
        right: calc(12px + 32px);
      }
      @include mq(lg) {
        right: calc(12px + 20px);
      }
    }
    &::after {
      top: 0;
      bottom: 0;
      width: 32px;
      height: 32px;
      // aspect-ratio: 32 / 32;
      margin: auto;
      border: 1px solid currentColor;
      border-radius: 50%;
      @include mq(min-lg) {
        right: calc(0px + 32px);
      }
      @include mq(lg) {
        right: calc(0px + 20px);
      }
    }
    @media (hover: hover) {
      &:hover {
        border-color: $color-accent;
        &::before {
          color: #fff;
        }
        &::after {
          background-color: $color-accent;
        }
      }
    }
  }
  &_ttl {
    font-weight: bold;
    line-height: 1.5;
    @include mq(min-lg) {
      font-size: 24px;
    }
    @include mq(lg) {
      font-size: 20px;
    }
  }
}

.box-service {
  $this: &;
  @include mq(min-md) {
    padding: math.percentage(math.div(40, 1100));
  }
  &_head {
    display: flex;
    gap: math.percentage(math.div(20, 1020));
    @include mq(min-md) {
      flex-direction: row-reverse;
    }
    @include mq(md) {
      flex-direction: column-reverse;
      gap: 25px;
      align-items: center;
    }
  }
  &_txt {
    flex: 1 1 auto;
    @include mq(min-md) {
    }
    @include mq(md) {
      font-size: 16px;
      line-height: 1.63;
    }
    .btn {
      @include mq(min-md) {
        justify-content: flex-start;
      }
      @include mq(md) {
      }
    }
  }
  &_img {
    @include mq(min-md) {
      flex: 0 0 160px;
    }
    @include mq(md) {
      max-width: 320px;
    }
  }
  &_ttl {
    font-weight: bold;
    line-height: 1.5;
    @include mq(min-md) {
      font-size: 36px;
    }
    @include mq(md) {
      font-size: 28px;
      text-align: center;
    }
    + * {
      margin-top: 1em;
    }
    .-small {
      @include mq(min-md) {
        font-size: 24px;
      }
      @include mq(md) {
        display: block;
        font-size: 20px;
      }
    }
  }
  &_subTtl {
    display: block;
    line-height: 1.5;
    @include mq(min-md) {
      font-size: 18px;
    }
    @include mq(md) {
      font-size: 16px;
    }
  }
  &_body {
    display: flex;
    margin-top: 24px;
    @include mq(min-md) {
      gap: math.percentage(math.div(32, 988));
    }
    @include mq(md) {
      flex-direction: column;
      gap: 30px;
    }
  }
  &_col {
    flex: 1;
    line-height: 1.63;
    .list-dot {
      li + li {
        @include mq(min-md) {
          margin-top: 0.15em;
        }
        @include mq(md) {
          margin-top: 0.3em;
        }
      }
    }
  }
  &_colH {
    padding: 0.5em calc(16em / 20);
    font-weight: bold;
    line-height: 1.5;
    background-color: #edeff3;
    @include mq(min-md) {
      font-size: 20px;
    }
    @include mq(md) {
      font-size: 18px;
    }
  }
  .btn {
    margin-top: 32px;
  }
  &#anc-01 {
    #{$this}_head {
      @include mq(min-md) {
        gap: math.percentage(math.div(55, 1020));
      }
    }
    #{$this}_img {
      @include mq(min-md) {
        flex: 0 0 325px;
      }
    }
  }
}

.sec-service {
  padding-top: 0;
}

.sideNav {
  $this: &;
  display: flex;
  @include mq(min-lg) {
    justify-content: space-between;
  }
  @include mq(lg) {
    flex-direction: column;
  }
  &_menu {
    position: relative;
    @include mq(min-lg) {
      width: math.percentage(math.div(244, 1100));
    }
    @include mq(lg) {
      width: auto;
    }
  }
  &_menuBox {
    @include mq(min-lg) {
      position: absolute;
      &.-fixed {
        position: fixed;
      }
    }
    @include mq(lg) {
      position: static;
      background-color: #fff;
      border-radius: 24px;
    }
  }
  &_ttl {
    position: relative;
    font-weight: bold;
    line-height: 1.5;
    @include mq(min-lg) {
      font-size: 18px;
    }
    @include mq(lg) {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 48px;
      overflow: hidden;
      font-size: 16px;
      background-color: #fff;
      border: 1px solid $color-accent;
      border-radius: 48px;
    }
    &::before {
      display: inline-block;
      // aspect-ratio: 1 / 1;
      margin-right: 0.5em;
      vertical-align: middle;
      content: "";
      background-color: $color-accent;
      background-image: url("/assets/images/common/icon-list.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      border-radius: 50%;
      @include mq(min-lg) {
        width: 24px;
        height: 24px;
      }
      @include mq(lg) {
        width: 20px;
        height: 20px;
      }
    }
    + * {
      @include mq(min-lg) {
        margin-top: 1.5em;
      }
      @include mq(lg) {
        margin-top: 0;
      }
    }
    &.-open {
      #{$this}_toggle {
        &::after {
          transform: rotate(0deg);
        }
      }
    }
  }
  &_toggle {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    @include mq(min-lg) {
      display: none;
    }
    @include mq(lg) {
      display: block;
    }
    &::before,
    &::after {
      position: absolute;
      top: 0;
      right: math.percentage(math.div(20, 375));
      bottom: 0;
      z-index: 1;
      display: block;
      width: 12px;
      height: 2px;
      margin: auto;
      content: "";
      background-color: $color-accent;
      transform: none;
    }
    &::after {
      transition: transform 0.3s;
      transform: rotate(-90deg);
    }
  }
  &_list {
    padding-left: 0;
    list-style: none;
    @include mq(min-lg) {
      display: block !important;
    }
    @include mq(lg) {
      display: none;
      padding: 10px 20px;
    }
  }
  &_item {
    position: relative;
    @include mq(min-lg) {
      padding-left: calc(25em / 16);
    }
    @include mq(lg) {
      display: flex;
      align-items: center;
    }
    & + & {
      @include mq(min-lg) {
        margin-top: 1em;
      }
      @include mq(lg) {
        border-top: 1px solid #ddd;
      }
    }
  }
  &_link {
    color: $color-text-base;
    text-decoration: none;
    transition: color 0.2s;
    @include mq(min-lg) {
      &.-active {
        color: $color-accent;
        &::before {
          background-color: currentColor;
        }
      }
    }
    @include mq(lg) {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 54px;
      padding-left: calc(25em / 16);
    }
    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: calc(9em / 16);
      height: calc(9em / 16);
      // aspect-ratio: 1 / 1;
      margin: auto;
      content: "";
      background-color: #dddddd;
      border-radius: 50%;
      transition: background-color 0.2s;
    }
    @media (hover: hover) {
      &:hover {
        color: $color-accent;
        &::before {
          background-color: currentColor;
        }
      }
    }
  }
  &_content {
    @include mq(min-lg) {
      width: math.percentage(math.div(800, 1100));
    }
    @include mq(lg) {
      width: auto;
      margin-top: 40px;
    }
  }
  .panel {
    @include mq(min-lg) {
      padding: 40px;
    }
    @include mq(lg) {
      padding: 32px math.percentage(math.div(24, 327));
      padding: 32px 24px;
    }
  }
}

.list-features {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(279px, 1fr));
  gap: 45px math.percentage(math.div(40, 720));
  padding-left: 0;
  list-style: none;
  + * {
    margin-top: 56px;
  }
  &_item {
    position: relative;
    border: 1px solid #ddd;
    @include mq(min-lg) {
      padding: math.percentage(math.div(38, 340)) math.percentage(math.div(32, 340)) math.percentage(math.div(32, 340));
      line-height: 1.63;
    }
    @include mq(lg) {
      padding: math.percentage(math.div(24, 279));
      line-height: 1.47;
    }
  }
  &_num {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: block;
    width: 100px;
    width: calc(100em / 36);
    margin: auto;
    font-family: $font-en;
    line-height: 1;
    text-align: center;
    background-color: #fff;
    transform: translateY(-50%);
    @include mq(min-lg) {
      font-size: 36px;
    }
    @include mq(lg) {
      font-size: 28px;
    }
    &::before,
    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      display: block;
      width: 1px;
      height: 0.7em;
      margin: auto;
      content: "";
      background-color: #333;
    }
    &::before {
      transform: translateX(-0.83em) rotate(-20deg);
    }
    &::after {
      transform: translateX(0.83em) rotate(20deg);
    }
  }
  &_img {
    max-width: 156px;
    margin-right: auto;
    margin-left: auto;
  }
  &_ttl {
    font-weight: bold;
    line-height: 1.25;
    text-align: center;
    @include mq(min-lg) {
      font-size: 18px;
    }
    @include mq(lg) {
      font-size: 16px;
    }
    + * {
      margin-top: 1em;
    }
  }
}

.list-analysis {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(279px, 1fr));
  padding-left: 0;
  list-style: none;
  @include mq(min-lg) {
    gap: 60px 40px;
  }
  @include mq(lg) {
    gap: 40px math.percentage(math.div(30, 621));
  }
  &_item {
    line-height: 1.63;
  }
  &_ttl {
    font-weight: bold;
    line-height: 1.5;
    text-align: center;
    @include mq(min-lg) {
      font-size: 18px;
    }
    @include mq(lg) {
      font-size: 16px;
    }
    + * {
      margin-top: 20px;
    }
  }
  &_modal {
    display: block;
    text-align: center;
    @include mq(min-lg) {
      cursor: pointer;
    }
    @include mq(lg) {
      pointer-events: none;
    }
  }
  &_img {
    aspect-ratio: 340 / 255;
    padding: math.percentage(math.div(13, 340));
    pointer-events: none;
    border: 1px solid #ddd;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &_zoom {
    font-size: 12px;
    line-height: 1.5;
    pointer-events: none;
    @include mq(lg) {
      display: none;
    }
    &::before {
      display: inline-block;
      width: calc(16em / 12);
      height: calc(16em / 12);
      // aspect-ratio: 1 / 1;
      margin-right: 0.5em;
      vertical-align: middle;
      content: "";
      background-image: url("/assets/images/common/icon-zoom.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }
  }
  &_subTtl {
    z-index: 0;
    margin-top: 1em;
    font-weight: bold;
    color: $color-dark;
    text-align: center;
    @include mq(min-lg) {
      font-size: 16px;
    }
    @include mq(lg) {
      font-size: 15px;
    }
    + * {
      margin-top: 0.5em;
    }
  }
}

.list-customerVoice {
  $this: &;
  padding-left: 0;
  list-style: none;
  &_item {
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: space-between;
    & + & {
      @include mq(min-lg) {
        margin-top: 24px;
      }
      @include mq(lg) {
        margin-top: 16px;
      }
    }
    &:nth-child(even) {
      flex-direction: row-reverse;
      #{$this}_txt {
        &::before {
          right: auto;
          left: 100%;
          transform: scaleX(-1);
        }
      }
    }
  }
  &_img {
    @include mq(min-lg) {
      width: 80px;
    }
    @include mq(lg) {
      width: math.percentage(math.div(56, 279));
    }
  }
  &_txt {
    position: relative;
    flex: 1;
    line-height: 1.63;
    border: 1px solid $color-text-base;
    @include mq(min-lg) {
      padding: 0.8em 20px;
    }
    @include mq(lg) {
      padding: math.percentage(math.div(16, 279));
    }
    &::before {
      position: absolute;
      top: 0;
      right: 100%;
      bottom: 0;
      display: block;
      width: 16px;
      height: 12px;
      margin: auto;
      clip-path: polygon(0 50%, 100% 0, 100% 100%);
      content: "";
      background-color: $color-text-base;
    }
    strong {
      @extend .underLine;
      background: linear-gradient(transparent 65%, #f4eb37 0%);
    }
  }
  &_post {
    display: inline-block;
    font-size: calc(14em / 16);
  }
}

.sec-serviceMenu {
  background-color: $color-bg-blue;
  @include mq(min-lg) {
    padding: 56px 0;
  }
  @include mq(lg) {
    padding: 40px 0;
  }
}

.serviceMenu {
  display: flex;
  @include mq(min-lg) {
    gap: math.percentage(math.div(56, 1100));
    align-items: center;
    justify-content: space-between;
  }
  @include mq(lg) {
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }
  &_head {
    .ttl-withEn {
      color: $color-dark;
      text-align: left;
      &_en {
        @include mq(min-lg) {
          font-size: 36px;
        }
        @include mq(lg) {
          font-size: 28px;
        }
      }
      a {
        color: $color-dark;
        text-decoration: none;
        .ttl-withEn_en {
          position: relative;
          padding-right: 16px;
          &::after {
            position: absolute;
            top: 0;
            // left: calc(100% + 0.3em);
            right: 0;
            bottom: 0.1em;
            width: calc(11em / 36);
            height: calc(11em / 36);
            // aspect-ratio: 1 / 1;
            margin: auto;
            content: "";
            border-color: currentColor;
            border-style: solid solid none none;
            border-width: calc(3em / 36);
            transform: rotate(45deg);
          }
        }
      }
    }
  }
  &_body {
    @include mq(min-lg) {
      width: math.percentage(math.div(930, 1100));
    }
    @include mq(lg) {
      width: 100%;
    }
  }
  &_list {
    display: flex;
    padding-left: 0;
    list-style: none;
    @include mq(min-lg) {
      gap: math.percentage(math.div(25, 930));
    }
    @include mq(lg) {
      flex-direction: column;
      gap: 16px;
    }
  }
  &_item {
    flex: 1;
  }
  &_link {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: $color-dark;
    text-decoration: none;
    background-color: #fff;
    transition: color 0.2s;
    @include mq(min-lg) {
      min-height: 72px;
    }
    @include mq(lg) {
      min-height: 56px;
    }
    &::after {
      position: absolute;
      right: 12px;
      bottom: 12px;
      display: block;
      width: 16px;
      aspect-ratio: 16 / 16;
      clip-path: polygon(100% 0%, 100% 100%, 0% 100%);
      content: "";
      background-color: $color-accent;
      transition: background-color 0.2s;
    }
    &.-active {
      color: rgba($color-dark, 0.4);
      &::after {
        background-color: rgba($color-accent, 0.4);
      }
    }
    @media (hover: hover) {
      &:hover {
        color: rgba($color-dark, 0.4);
        &::after {
          background-color: rgba($color-accent, 0.4);
        }
      }
    }
  }
}

.list-howToBuy {
  padding-left: 0;
  list-style: none;
  &_item {
    display: flex;
    padding: 20px;
    border: 4px solid #f4f4f4;
    @include mq(min-lg) {
      gap: 24px;
      align-items: center;
      justify-content: space-between;
    }
    @include mq(lg) {
      flex-direction: column;
      gap: 12px;
    }
    & + & {
      margin-top: 1em;
    }
  }
  &_img {
    width: 150px;
    @include mq(min-lg) {
    }
    @include mq(lg) {
      margin-right: auto;
      margin-left: auto;
    }
  }
  &_txt {
    flex: 1;
    line-height: 1.63;
  }
  &_ttl {
    display: flex;
    gap: 12px;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    i {
      display: flex;
      flex: 0 0 24px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 24px;
      aspect-ratio: 1 / 1;
      font-family: $font-en;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      color: #fff;
      background-color: $color-dark;
      border-radius: 50%;
    }
  }
}

.reportContent {
  position: relative;
  background-color: #edeff3;
  @include mq(min-lg) {
    padding: math.percentage(math.div(32, 720));
  }
  @include mq(lg) {
    padding: math.percentage(math.div(24, 279)) math.percentage(math.div(16, 279));
  }
  + .ttl-block {
    margin-top: 56px;
  }
  &_tag {
    position: absolute;
    top: 0;
    padding: 6px 16px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.2;
    color: #fff;
    background-color: $color-accent;
    transform: translateY(-50%);
    @include mq(lg) {
      left: 0;
    }
    &::after {
      position: absolute;
      top: 0;
      left: 100%;
      display: block;
      width: 10px;
      height: 100%;
      clip-path: polygon(0% 100%, 100% 100%, 0% 0%);
      content: "";
      background-color: $color-accent;
    }
  }
  &_head {
    display: flex;
    @include mq(min-lg) {
      align-items: center;
      justify-content: space-between;
    }
    @include mq(lg) {
      flex-direction: column;
      gap: 1em;
      .btn {
        a {
          height: 50px;
          font-size: 15px;
        }
      }
    }
  }
  &_ttl {
    display: flex;
    gap: 12px;
    align-items: center;
    font-weight: bold;
    line-height: 1.5;
    @include mq(min-lg) {
      font-size: 20px;
    }
    @include mq(lg) {
      font-size: 18px;
    }
    + .list-note {
      margin-top: 0.2em;
    }
  }
  &_body {
    display: flex;
    gap: 4px;
    @include mq(min-lg) {
      margin-top: 1em;
    }
    @include mq(lg) {
      flex-direction: column;
      margin-top: 20px;
    }
  }
  &_card {
    flex: 1;
    background-color: #fff;
    @include mq(min-lg) {
      padding: math.percentage(math.div(20, 656));
    }
    @include mq(lg) {
      padding: math.percentage(math.div(20, 247));
    }
    .list-dot {
      li + li {
        margin-top: 0.3em;
      }
    }
  }
  &_cardTtl {
    display: flex;
    gap: 12px;
    align-items: center;
    font-weight: bold;
    + * {
      margin-top: 1em;
    }
    i {
      @extend %circleNum;
    }
  }
  &_listCol {
    display: flex;
    @include mq(min-lg) {
      gap: 1em;
    }
    @include mq(lg) {
      flex-direction: column;
      gap: 0.3em;
    }
    .list-dot {
      flex: auto;
      margin-top: 0;
    }
  }
  &_plus {
    position: relative;
    height: 24px;
    text-align: center;
    @include mq(min-lg) {
      margin: 26px 0 40px;
    }
    @include mq(lg) {
      margin: 16px 0 30px;
    }
    &::before,
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      display: block;
      width: 24px;
      height: 4px;
      margin: auto;
      content: "";
      background-color: $color-text-base;
      transform: none;
    }
    &::after {
      transform: rotate(-90deg);
    }
  }
}

.dl-pullDown {
  &_head {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 56px;
    font-weight: bold;
    line-height: 1.5;
    @include mq(min-lg) {
      padding: 0.5em 40px 0.5em 20px;
    }
    @include mq(lg) {
      padding: 0.8em 40px 0.8em 20px;
    }
    cursor: pointer;
    background-color: #fff;
    border: 4px solid #f4f4f4;
    border-radius: 28px;
    transition: color 0.3s, border-color 0.3s;
    @media (hover: hover) {
      &:hover {
        color: $color-accent;
        border-color: currentColor;
      }
    }
    &::before,
    &::after {
      position: absolute;
      top: 0;
      right: 20px;
      bottom: 0;
      z-index: 1;
      display: block;
      width: 12px;
      height: 2px;
      margin: auto;
      content: "";
      background-color: $color-accent;
      transform: none;
    }
    &::after {
      transition: transform 0.3s;
      transform: rotate(-90deg);
    }
    &.-open {
      &::after {
        transform: rotate(0deg);
      }
    }
  }
  &_body {
    position: relative;
    z-index: 0;
    display: none;
    padding: 50px 24px 24px;
    margin: 0px 0 -28px;
    background-color: #f4f4f4;
    transform: translateY(-28px);
    + dt {
      margin-top: 1em;
    }
  }
  &_list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 0.8em 1em;
    padding-left: 0;
    list-style: none;
  }
}

.consultDetails {
  display: flex;
  margin-top: 1em;
  @include mq(min-lg) {
    gap: 24px;
  }
  @include mq(lg) {
    flex-direction: column;
    gap: 16px;
  }
  &_col {
    flex: 1;
    border: 1px solid #ddd;
    @include mq(min-lg) {
      padding: math.percentage(math.div(24, 720));
    }
    @include mq(lg) {
      padding: math.percentage(math.div(20, 279));
    }
    .list-dot {
      li + li {
        margin-top: 0.2em;
      }
    }
  }
  &_ttl {
    display: flex;
    gap: 0.7em;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    i {
      display: inline-block;
      flex: 0 0 auto;
    }
  }
}

.list-orderFlow {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 64px;
  padding-left: 0;
  list-style: none;
  &::before {
    position: absolute;
    top: 0;
    left: 50px;
    z-index: 0;
    width: 1px;
    height: 100%;
    content: "";
    background-color: $color-accent;
    @include mq(min-lg) {
      display: block;
    }
    @include mq(lg) {
      display: none;
    }
  }
  &_row {
    display: flex;
    align-items: center;
    @include mq(min-lg) {
      gap: 24px;
    }
    @include mq(lg) {
      gap: 0px;
    }
  }
  &_item {
    position: relative;
    + .list-orderFlow_item {
      &::before {
        @extend %dotLine;
        top: -32px;
        right: 0;
        bottom: auto;
        left: auto;
        display: block;
        @include mq(min-md) {
          width: calc(100% - 124px);
        }
        @include mq(md) {
          width: 100%;
        }
      }
    }
  }
  &_step {
    position: relative;
    z-index: 1;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 0.2em;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1 / 1;
    font-family: $font-en;
    line-height: 1;
    color: $color-accent;
    background-color: #fff;
    border: 1px solid $color-accent;
    border-radius: 50%;
    @include mq(min-lg) {
      width: 100px;
      font-size: 18px;
    }
    @include mq(lg) {
      width: 72px;
      margin-right: 12px;
      font-size: 12px;
    }
    .-num {
      @include mq(min-lg) {
        font-size: 36px;
      }
      @include mq(lg) {
        font-size: 25px;
      }
    }
  }
  &_ttl {
    font-weight: bold;
    line-height: 1.5;
    @include mq(min-lg) {
      font-size: 18px;
    }
    @include mq(lg) {
      font-size: 16px;
    }
  }
  &_img {
    margin-left: auto;
    @include mq(min-lg) {
      width: 88px;
    }
    @include mq(lg) {
      width: 63px;
    }
  }
}

.sec-qandaToc {
  padding: 0;
}

.toc {
  display: flex;
  background-color: #fff;
  border-color: $color-accent;
  border-style: solid none;
  border-width: 2px;
  @include mq(min-lg) {
    gap: 48px;
    padding: math.percentage(math.div(56, 1100));
  }
  @include mq(lg) {
    flex-direction: column;
    gap: 15px;
    padding: math.percentage(math.div(30, 327)) math.percentage(math.div(24, 327));
  }
  &_head {
    flex: 0 1 auto;
  }
  &_ttl {
    font-weight: bold;
    line-height: 1.2;
    @include mq(min-lg) {
      font-size: 24px;
    }
    @include mq(lg) {
      font-size: 20px;
    }
  }
  &_body {
    flex: auto;
  }
  &_list {
    padding-left: 0;
    margin-top: -16px;
    line-height: 1.5;
    list-style: none;
    counter-reset: num 0;
  }
  &_item {
    position: relative;
    &::before {
      position: absolute;
      top: 1em;
      bottom: 1em;
      left: 0;
      height: 1em;
      font-family: $font-en;
      font-size: 24px;
      line-height: 1;
      color: $color-accent;
      pointer-events: none;
      content: "Q" counter(num);
      counter-increment: num;
      @include mq(min-lg) {
        margin: auto;
      }
      @include mq(lg) {
        top: 0.8em;
        bottom: auto;
      }
    }
    &::after {
      @extend %dotLine;
    }
  }
  &_link {
    display: flex;
    align-items: center;
    min-height: 63px;
    padding: 1em 44px 1em 35px;
    color: $color-text-base;
    text-decoration: none;
    @extend %circleArrow;
    &::before {
      right: 12px;
      transform: rotate(135deg);
    }
  }
}

.sec-qanda {
  padding-top: 40px;
  .panel {
    @include mq(min-lg) {
      padding: math.percentage(math.div(40, 1100)) math.percentage(math.div(40, 1100)) math.percentage(math.div(60, 1100));
    }
    @include mq(lg) {
      padding: 40px math.percentage(math.div(24, 327)) 60px;
    }
  }
}

.qanda {
  position: relative;
  @include mq(lg) {
    margin-top: 12px;
  }
  & + & {
    @include mq(lg) {
      margin-top: 52px;
    }
  }
  &_question {
    display: flex;
    gap: 16px;
    align-items: center;
    font-weight: bold;
    line-height: 1.5;
    @include mq(min-lg) {
      font-size: 24px;
    }
    @include mq(lg) {
      font-size: 20px;
    }
    i {
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 2em;
      aspect-ratio: 1 / 1;
      font-family: $font-en;
      font-style: normal;
      font-weight: normal;
      color: #fff;
      background-color: $color-accent;
      border-radius: 50%;
      @include mq(min-lg) {
        font-size: 32px;
      }
      @include mq(lg) {
        position: absolute;
        top: 0;
        font-size: 28px;
        transform: translateY(-50%);
      }
    }
  }
  &_answer {
    font-weight: bold;
    line-height: 1.5;
    background-color: #edeff3;
    @include mq(min-lg) {
      padding: 0.4em calc(16em / 20);
      font-size: 20px;
    }
    @include mq(lg) {
      padding: 0.5em calc(11em / 18);
      font-size: 18px;
    }
    + * {
      margin-top: 1.5em;
    }
    i {
      position: relative;
      display: inline-block;
      margin-right: 1em;
      font-style: normal;
      color: $color-accent;
    }
  }
  &_body {
    margin-top: 24px;
  }
  &_figure {
    margin: 2em 0 0;
    text-align: center;
    + * {
      margin-top: 2em;
    }
    img {
      width: calc(1020px * 0.8);
    }
  }
  &_pagetop {
    position: absolute;
    right: 30px;
    @include mq(min-lg) {
      right: math.percentage(math.div(45, 1100));
      bottom: 1.4em;
      font-size: 14px;
    }
    @include mq(lg) {
      right: math.percentage(math.div(30, 327));
      bottom: 1.4em;
      font-size: 13px;
    }
  }
}

.dl-num {
  counter-reset: num 0;
  @include mq(min-lg) {
    margin-top: 1.5em;
  }
  @include mq(lg) {
    margin-top: 1.5em;
  }
  &:first-child {
    margin-top: 0;
  }
  + * {
    @include mq(min-lg) {
      margin-top: 1.5em;
    }
    @include mq(lg) {
      margin-top: 1.2em;
    }
  }
  &_ttl {
    position: relative;
    display: flex;
    padding: 0.5em 0.8em 0.5em calc(52em / 16);
    font-weight: bold;
    line-height: 1.5;
    color: $color-dark;
    border: 4px solid #f4f4f4;
    @include mq(min-lg) {
      gap: 12px;
      align-items: center;
      min-height: 56px;
    }
    @include mq(lg) {
      flex-direction: column;
      gap: 4px;
      align-items: flex-start;
      min-height: 48px;
    }
    + dt {
      margin-top: 0.5em;
    }
    + dd {
      margin-top: 1em;
    }
    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 1em;
      margin: auto;
      content: counter(num);
      counter-increment: num;
      @extend %circleNum;
    }
    em {
      padding: 0.2em 0.8em;
      font-style: normal;
      background-color: #ddd;
      &.-red {
        color: #c31616;
        background-color: #f7eaea;
      }
      &.-blue {
        color: #0a55aa;
        background-color: #edeff3;
      }
    }
  }
  &_txt {
    margin-left: 0;
    + dt {
      margin-top: 1.5em;
    }
  }
}

.bnr-bizcruncher {
  margin-top: 3em;
  img {
    transition: opacity 0.2s;
  }
  a {
    display: block;
    @media (hover: hover) {
      &:hover {
        img {
          opacity: 0.7;
        }
      }
    }
  }
  &_cap {
    margin-top: 1.5em;
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
  }
}
