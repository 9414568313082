@use "../abstracts" as *;
@use "sass:math";

.sec-topMv {
  padding: 0;
}

.topMv {
  &_list {
    padding-left: 0;
    list-style: none;
  }
  &_item {
  }
  &.swiper {
    .swiper-pagination {
      top: auto;
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      justify-content: center;
      @include mq(min-md) {
        bottom: calc(30vw / 1024 * 100);
      }
      @include mq(min-lg) {
        bottom: 40px;
      }
      @include mq(md) {
        bottom: math.percentage(math.div(32, 522));
      }
      &-bullet {
        width: 40px;
        height: 2px;
        margin: 0;
        text-indent: -999em;
        border-radius: 0;
        opacity: 1;
      }
    }
  }
}

.topMvSlide {
  box-sizing: border-box;
  width: 100%;
  max-width: 1366px;
  height: 100%;
  margin: 0 auto;
  @include mq(min-md) {
    position: relative;
    aspect-ratio: 1366 / 454;
  }
  @include mq(md) {
    aspect-ratio: 375 / 522;
  }
  &_bg {
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .btn {
    position: absolute;
    gap: math.percentage(math.div(23.7, 584));
    @include mq(min-md) {
      right: math.percentage(math.div(133, 1366));
      bottom: math.percentage(math.div(82, 454));
      justify-content: flex-end;
      width: math.percentage(math.div(584, 1366));
      font-size: calc(18vw / 1366 * 100);
    }
    @include mq(min-xl) {
      font-size: 18px;
    }
    @include mq(md) {
      right: 0;
      bottom: math.percentage(math.div(66, 522));
      left: 0;
      justify-content: center;
      width: math.percentage(math.div(327, 375));
      margin: 0 auto;
      font-size: 14px;
      font-size: calc(14vw / 375 * 100);
    }
    a {
      min-width: math.percentage(math.div(280, 584));
      @include mq(min-md) {
        height: calc(58vw / 1366 * 100);
        border-radius: 999em;
      }
      @include mq(min-xl) {
        width: 280px;
        height: 58px;
      }
      @include mq(md) {
        min-width: auto;
        height: calc(50vw / 375 * 100);
      }
    }
  }
}

.bar-pagination {
  position: relative;
  background-color: #ddd;
  &_inner {
    position: absolute;
    display: block;
    width: 0%;
    height: 100%;
    background-color: $color-dark;
    transition-timing-function: linear;
    transition-property: width;
  }
  &.swiper-pagination-bullet-active & {
    &_inner {
      width: 100%;
      transition-duration: inherit;
    }
  }
}

.pickupSlide {
  @include mq(md) {
    overflow: visible;
  }
  &_list {
    display: flex;
    padding-left: 0;
    list-style: none;
  }
  &_item {
    width: 216px;
  }
  &_controller {
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    .swiper-scrollbar {
      position: static;
      height: 2px;
      background-color: #417bbc;
      background-color: rgba(#fff, 0.25);
      @include mq(min-md) {
        width: math.percentage(math.div(156, 1180));
      }
      @include mq(md) {
        width: 156px;
      }
      &-drag {
        background-color: #fdfcfc;
      }
    }
    .swiper-button {
      margin: 0;
    }
  }
}

.sec-pickup {
  padding: 40px 0;
  overflow: hidden;
  color: #fff;
  background-color: $color-accent;
  .container {
    max-width: $contentsWidth + 80 + $contentsPadding * 2;
  }
  .ttl-withEn {
    display: flex;
    flex-direction: row-reverse;
    gap: 12px;
    align-items: baseline;
    justify-content: flex-end;
    @include mq(md) {
      &_en {
        font-size: 22px;
      }
    }
    + .pickupSlide {
      margin-top: 16px;
    }
  }
}

.swiper {
  .swiper-button {
    display: flex;
    gap: 16px;
    align-items: center;
    padding-left: 0;
    list-style: none;
    &-prev,
    &-next {
      position: static;
      width: 40px;
      height: auto;
      aspect-ratio: 40 / 40;
      margin: 0;
      border: 1px solid #fff;
      border-radius: 50%;
      transition: opacity 0.3s;
      &::before {
        width: 8px;
        aspect-ratio: 1 / 1;
        margin: auto;
        content: "";
        border-color: #fff;
        border-style: none none solid solid;
        border-width: 2.5px;
        transform: translateX(15%) rotate(45deg);
      }
      &::after {
        content: none;
      }
    }
    &-next {
      &::before {
        transform: translateX(-15%) rotate(-135deg);
      }
    }
    &-disabled {
      opacity: 0.25;
    }
  }
}

.sec-topNews,
.sec-topRanking {
  background-color: #e1e5eb;
}

.list-article {
  $this: &;
  padding-left: 0;
  list-style: none;
  .ttl-withEn + & {
    margin-top: 0.5em;
  }
  &_item {
    .sec-topSeminar & {
      &:nth-child(n + 4) {
        display: none;
      }
    }
  }
  &_link {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $color-text-base;
    text-decoration: none;
    border-bottom: 1px solid #ddd;
    transition: border-color 0.3s;
    @include mq(min-md) {
      min-height: 80px;
      padding: 0.9em 56px 0.9em 0;
    }
    @include mq(md) {
      padding: 1.2em 40px 1.2em 0;
    }
    @extend %circleArrow;
  }
  &_date {
    font-size: 14px;
    line-height: 1.5;
    color: $color-accent;
  }
  &_title {
    display: -webkit-box;
    margin-top: 0.2em;
    overflow: hidden;
    line-height: 1.5;
    -webkit-box-orient: vertical;
    @include mq(min-md) {
      -webkit-line-clamp: 1;
    }
    @include mq(md) {
      -webkit-line-clamp: 4;
    }
  }
  &_tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
    padding: 0;
    margin: 0;
    font-size: 12px;
    line-height: 1.2;
    list-style: none;
    + * {
      margin-top: 8px;
    }
    .-new & {
      &::before {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.2em 0.7em;
        font-weight: bold;
        color: #fff;
        content: "NEW";
        background-color: $color-accent;
      }
    }
  }
  &_tag {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 22px;
    padding: 0.2em 0.7em;
    background-color: $color-bg-base;
    .postHeader & {
      min-height: 26px;
      padding: 0.2em 8px;
      color: $color-dark;
      background-color: #fff;
    }
  }
  &_industries {
    margin-top: 4px;
    font-size: 12px;
    line-height: 1.5;
    color: $color-accent;
    .postHeader & {
      color: #727272;
    }
  }
  &_summary {
    margin-top: 0.5em;
    font-size: 14px;
    line-height: 1.43;
    color: #727272;
  }
}

.box-articleList {
  display: flex;
  margin-top: 40px;
  color: $color-text-base;
  text-align: left;
  background-color: #fff;
  @include mq(min-md) {
    justify-content: space-between;
    padding: math.percentage(math.div(56, 1100)) math.percentage(math.div(64, 1100));
  }
  @include mq(md) {
    flex-direction: column;
    gap: 50px;
    padding: 54px math.percentage(math.div(24, 327));
  }
  &:first-child {
    margin-top: 0;
  }
  &_col {
    @include mq(min-md) {
      width: math.percentage(math.div(458, 972));
    }
    @include mq(md) {
      width: auto;
    }
    &.-real {
      .list-article {
        &_item {
          a {
            &::before,
            &::after {
              color: #be741f;
            }
            @media (hover: hover) {
              &:hover {
                border-color: #be741f;
                &::before {
                  color: #fff;
                }
              }
            }
          }
        }
        &_date {
          color: #be741f;
        }
      }
    }
  }
  .ttl-withEn {
    text-align: left;
  }
  .btn {
    justify-content: flex-end;
    margin-top: 32px;
  }
  &.-vertical {
    flex-direction: column;
    gap: 50px;
  }
  &.-vertical & {
    &_col {
      width: 100%;
    }
  }
}

.sec-topService {
  @include mq(min-md) {
    text-align: center;
  }
  @include mq(md) {
    text-align: left;
  }
}

.card-topService {
  display: grid;
  gap: 32px;
  padding-left: 0;
  margin-top: 40px;
  text-align: left;
  list-style: none;
  @include mq(min-md) {
    grid-template-columns: repeat(auto-fill, minmax(534px, 1fr));
  }
  @include mq(md) {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
  &_item {
    background-color: #fff;
    @include mq(min-md) {
      padding: math.percentage(math.div(30, 534));
    }
    @include mq(md) {
      padding: 32px math.percentage(math.div(24, 327));
    }
    .btn {
      margin-top: 24px;
      @include mq(min-md) {
        justify-content: flex-end;
      }
      @include mq(md) {
        justify-content: center;
      }
    }
  }
  &_inr {
    display: flex;
    @include mq(min-md) {
      gap: math.percentage(math.div(30, 474));
      justify-content: space-between;
      // min-height: 320px;
    }
    @include mq(md) {
      flex-direction: column;
      gap: 24px;
    }
  }
  &_head {
    flex: 1 0 auto;
    align-self: center;
    @include mq(min-md) {
      width: math.percentage(math.div(156, 474));
    }
    @include mq(md) {
      width: auto;
      max-width: math.percentage(math.div(206, 279));
      img {
        width: auto;
        height: 144px;
      }
    }
  }
  &_body {
    display: flex;
    flex: auto;
    flex-direction: column;
    line-height: 1.625;
  }
  &_txt {
    margin-bottom: auto;
  }
  &_ttl {
    font-weight: bold;
    line-height: 1.5;
    @include mq(min-md) {
      font-size: 24px;
    }
    @include mq(md) {
      font-size: 20px;
      text-align: center;
    }
  }
}

.sec-topSeminar {
  color: #fff;
  background-color: $color-accent;
  background-image: url("/assets/images/top/bg-triangleMosaic.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @include mq(min-md) {
    text-align: center;
  }
  @include mq(md) {
    text-align: left;
  }
}

.box-topAbout {
  display: flex;
  @include mq(min-md) {
    gap: math.percentage(math.div(106, 1100));
  }
  @include mq(md) {
    flex-direction: column-reverse;
    gap: 30px;
    align-items: center;
  }
  &_img {
    flex: 1 0 auto;
    @include mq(min-md) {
      width: math.percentage(math.div(444, 1100));
    }
    @include mq(md) {
      max-width: 327px;
    }
  }
  &_txt {
    line-height: 1.75;
    .ttl-withEn {
      @include mq(min-md) {
        text-align: left;
      }
      @include mq(md) {
        text-align: center;
      }
    }
    .btn {
      @include mq(min-md) {
        justify-content: flex-end;
      }
      @include mq(md) {
        justify-content: center;
      }
    }
  }
  &_catch {
    font-weight: bold;
    line-height: 1.5;
    @include mq(min-md) {
      font-size: 20px;
    }
    @include mq(md) {
      font-size: 18px;
    }
  }
}
