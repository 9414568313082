@use "../abstracts" as *;
@use "sass:math";

[class^="sec-"],
[class*=" sec-"] {
  padding-top: 80px;
  padding-bottom: 80px;
  @include mq(md) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

.l-main {
  position: relative;
  // SERVICEページ目次のアクティブ切り替え位置の可視化
  &::before {
    position: fixed;
    // top: 95px;
    top: 20%;
    z-index: 999;
    display: block;
    width: 100%;
    height: 1px;
    pointer-events: none;
    visibility: hidden;
    content: "";
    background-color: rgba(#f00, 0.5);
  }
}

.sec-pageHeader {
  @include mq(min-md) {
    padding: 95px 0 75px;
  }
  @include mq(md) {
    padding: 40px 0 60px;
  }
  + * {
    padding-top: 0;
  }
}

.nav-breadcrumb {
  font-size: 12px;
  line-height: 1.2;
  @include mq(min-md) {
    position: absolute;
    top: 0;
    left: 0;
    padding: 1em 32px;
  }
  @include mq(md) {
    padding: 1em 16px;
  }
  &_list {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-right: (math.div(-(20em - 0.57em), 14));
    list-style: none;
  }
  &_item {
    position: relative;
    padding: 0 (math.div((15em - 0.57em), 12)) 0 (math.div((15em + 0.57em), 12));
    margin: 0.3em 0;
    &:first-child {
      padding-left: 0;
    }
    & + & {
      &::before {
        position: absolute;
        top: 0.2em;
        left: 0em;
        display: block;
        width: 0.7em;
        height: 0.7em;
        content: "";
        border-color: #a7a7a7;
        border-style: solid solid none none;
        border-width: 1px;
        transform: translateX(-70%) rotate(42.5deg) skew(-20deg, -20deg);
      }
    }
  }
  &_link {
    color: #a7a7a7;
    text-decoration: none;
    transition: color 0.2s;
    @media (hover: hover) {
      &:hover {
        color: $color-text-base;
        text-decoration: underline;
      }
    }
  }
}

.ttl-page {
  font-size: 24px;
  line-height: 1.5;
  text-align: center;
  + * {
    @include mq(min-md) {
      margin-top: 30px;
    }
    @include mq(md) {
      margin-top: 10px;
    }
  }
  &_ja {
    display: block;
    font-size: 14px;
    font-weight: bold;
    a {
      position: relative;
      color: $color-text-base;
      text-decoration: none;
      transition: color 0.2s;
      &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: calc(100% + 0.5em);
        width: calc(8em / 16);
        height: calc(8em / 16);
        // aspect-ratio: 1 / 1;
        margin: auto;
        content: "";
        border-color: $color-accent;
        border-style: solid solid none none;
        border-width: 2.5px;
        border-width: calc(2.5em / 16);
        transform: rotate(45deg);
      }
      @media (hover: hover) {
        &:hover {
          color: $color-accent;
        }
      }
    }
  }
  &_en {
    display: block;
    font-family: $font-en;
    line-height: 1.2;
    letter-spacing: 0.04em;
    @include mq(min-md) {
      font-size: 64px;
    }
    @include mq(md) {
      font-size: 48px;
    }
  }
  &_main {
    font-weight: bold;
    @include mq(min-md) {
      font-size: 48px;
    }
    @include mq(md) {
      font-size: 36px;
    }
  }
}

.read-pageHeader {
  @include mq(min-md) {
    text-align: center;
  }
  @include mq(md) {
    line-height: 1.87;
    text-align: left;
  }
  &.-accent {
    font-weight: bold;
    line-height: 1.67;
    color: $color-accent;
    @include mq(min-md) {
      font-size: 18px;
    }
    @include mq(md) {
      font-size: 16px;
      text-align: center;
    }
  }
}
