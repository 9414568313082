@use "../abstracts" as *;

html,
body {
  text-size-adjust: 100%;
}

html {
  font-size: 62.5%;
  // @include mq(lg) {
  //   font-size: get_vw(10);
  // }
  // @include mq(md) {
  //   font-size: 62.5%;
  // }
  // @include mq(xs) {
  //   font-size: get_vw(10, xs);
  // }
}

body {
  box-sizing: border-box;
  overflow-y: scroll;
  font-family: $font-base;
  line-height: 2;
  color: $color-text-base;
  word-wrap: break-word;
  background-color: $color-bg-base;
  -webkit-font-smoothing: antialiased;
  backface-visibility: hidden;
  @include mq(min-md) {
    font-size: 16px;
  }
  @include mq(md) {
    font-size: 15px;
  }
}

* {
  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

a {
  color: $color-text-link;
  text-decoration: underline;
  outline: none;
  @media (hover: hover) {
    &:hover {
      text-decoration: none;
    }
  }
  &[href$=".pdf"] {
    // color: $color-text-base;
    // text-decoration: none;
    transition: color 0.2s;
    @media (hover: hover) {
      &:hover {
        // color: $color-accent;
      }
    }
    &::before {
      display: inline-block;
      width: calc(20em / 16);
      aspect-ratio: 1 / 1;
      margin-right: 0.4em;
      vertical-align: -0.3em;
      content: "";
      background-color: $color-accent;
      mask-image: url("/assets/images/common/icon-pdf.svg");
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-position: center center;
    }
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

picture {
  display: block;
}

table {
  border-collapse: collapse;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
dl {
  margin-bottom: 0;
  font-weight: normal;
}
