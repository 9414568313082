@use "../abstracts" as *;
@use "sass:math";

.l-footer {
  color: #fff;
  background-color: $color-dark;
  a {
    color: #fff;
    text-decoration: none;
  }
}

.ft-main {
  background-color: $color-bg-base;
  @include mq(min-lg) {
    padding: 80px 0;
  }
  @include mq(lg) {
    padding: 40px 0;
  }
  .container {
    @include mq(md) {
      padding: 0;
    }
  }
  a {
    color: $color-text-base;
  }
  &_head {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include mq(min-md) {
      width: 100%;
      img {
        width: 150px;
      }
    }
    @include mq(min-lg) {
      width: auto;
    }
  }
  &_logo {
    display: block;
    @include mq(md) {
      height: 100px;
      img {
        width: auto;
        height: 100%;
      }
    }
  }
  &_wrap {
    display: flex;
    @include mq(md) {
      flex-direction: column;
    }
    @include mq(min-md) {
      flex-wrap: wrap;
      gap: 50px 2%;
      justify-content: space-between;
    }
    @include mq(min-lg) {
      flex-wrap: nowrap;
      gap: 5%;
      justify-content: space-between;
    }
  }
  &_body {
    display: flex;
    flex: auto;
    @include mq(md) {
      flex-direction: column;
      margin-top: 40px;
    }
    @include mq(min-md) {
      gap: 4%;
      justify-content: space-between;
      max-width: math.percentage(math.div(680, 924));
    }
    @include mq(min-lg) {
      // max-width: math.percentage(math.div(580, 1100));
      max-width: auto;
    }
  }
  &_foot {
    @include mq(min-md) {
      width: 168px;
    }
    @include mq(md) {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 math.percentage(math.div(20, 375));
      margin-top: 40px;
    }
  }
}

.ft-contact {
  background-color: $color-accent;
  + * {
    @include mq(min-md) {
      margin-top: 24px;
    }
    @include mq(md) {
      margin-top: 20px;
    }
  }
  .container {
    height: 100%;
  }
  &_wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    @include mq(min-md) {
      min-height: 237px;
      padding: 30px 0;
    }
    @include mq(md) {
      flex-direction: column;
      gap: 35px;
      padding: 64px 0;
    }
  }
  .ttl-withEn {
    display: flex;
    @include mq(min-md) {
      flex-direction: row-reverse;
      gap: 32px;
      align-items: center;
    }
    @include mq(md) {
      flex-direction: column-reverse;
    }
    &_ja {
      @include mq(min-md) {
        font-size: 16px;
      }
      @include mq(md) {
        font-size: 15px;
        line-height: 1.87;
      }
    }
    &_en {
      @include mq(min-md) {
        font-size: 64px;
      }
      @include mq(md) {
        font-size: 48px;
      }
    }
  }
  .btn {
    margin-top: 0;
    a {
      color: $color-accent;
      border-style: none;
      @include mq(min-md) {
        min-width: 15em;
        height: 67px;
        font-size: 18px;
      }
      @include mq(md) {
        min-width: 16.4em;
        height: 56px;
        font-size: 16px;
      }
    }
  }
}

.nav-ftMain {
  padding-left: 0;
  margin: 0;
  font-weight: bold;
  line-height: 1.5;
  list-style: none;
  @include mq(lg) {
    font-size: 15px;
  }
  @include mq(md) {
    &:last-child {
      border-bottom: 1px solid #ddd;
    }
  }
  &_link,
  &_subLink {
    transition: color 0.3s;
    @media (hover: hover) {
      &:hover {
        // text-decoration: underline;
        // color: #1955aa;
        color: $color-accent;
      }
    }
    @include mq(md) {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0.5em math.percentage(math.div(40, 375)) 0.5em math.percentage(math.div(20, 375));
      &::after {
        position: absolute;
        top: 0;
        right: math.percentage(math.div(20, 375));
        bottom: 0;
        z-index: 1;
        width: 7px;
        height: 7px;
        // aspect-ratio: 1 / 1;
        margin: auto;
        content: "";
        border-color: currentColor;
        border-style: solid solid none none;
        border-width: 2px;
        transform: rotate(45deg);
      }
    }
  }
  &_item {
    @include mq(min-md) {
      & + & {
        margin-top: 1em;
      }
    }
    @include mq(md) {
      border-top: 1px solid #ddd;
    }
  }
  &_link {
    @include mq(md) {
      min-height: 55px;
      &.-sub {
        &::before,
        &::after {
          position: absolute;
          top: 0;
          right: math.percentage(math.div(20, 375));
          bottom: 0;
          display: block;
          width: 10px;
          height: 2px;
          margin: auto;
          content: "";
          background-color: #333;
          transform: none;
        }
        &::after {
          transition: transform 0.3s;
          transform: rotate(-90deg);
        }
        &.-open {
          &::after {
            transform: rotate(0deg);
          }
        }
      }
    }
  }
  &_toggle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include mq(min-md) {
      display: none;
    }
    @include mq(md) {
      display: block;
    }
  }
  &_sub {
    padding-left: 0em;
    font-size: 15px;
    list-style: none;
    @include mq(min-md) {
      display: block !important;
      margin-top: 0.6em;
    }
    @include mq(lg) {
      font-size: 14px;
    }
    @include mq(md) {
      display: none;
      background-color: #e1e5eb;
    }
  }
  &_subItem {
    @include mq(min-md) {
      & + & {
        margin-top: 0.5em;
      }
      &::before {
        color: $color-text-base;
        content: "・";
      }
    }
    @include mq(md) {
      border-top: 1px solid #ddd;
    }
  }
  &_subLink {
    @include mq(md) {
      min-height: 47px;
      &::before {
        color: $color-text-base;
        content: "・";
      }
    }
  }
}

.list-sns {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0;
  list-style: none;
  @include mq(min-md) {
    gap: 32px;
  }
  @include mq(md) {
    gap: 24px;
  }
  &_item {
    @include mq(md) {
      width: 24px;
    }
    img {
      width: 100%;
    }
  }
  &_link {
    transition: opacity 0.2s;
    @media (hover: hover) {
      &:hover {
        opacity: 0.4;
      }
    }
  }
}

.bnr-iso {
  @include mq(min-md) {
    width: auto;
    margin-top: 32px;
  }
  @include mq(md) {
    width: 127px;
    margin-top: 16px;
  }
}

.ft-bottom {
  overflow: hidden;
  font-size: 14px;
  &_wrap {
    display: flex;
    @include mq(min-hd) {
      height: 59px;
    }
    @include mq(hd) {
      flex-direction: column;
    }
  }
  &_menu {
    @include mq(min-hd) {
      display: flex;
      flex: auto;
      flex-direction: column;
      justify-content: center;
    }
    @include mq(hd) {
      padding: 20px 0;
    }
  }
  &_cr {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    background-color: $color-accent;
    @include mq(min-hd) {
      width: math.percentage(math.div(340, 1100));
      &::before {
        position: absolute;
        right: 100%;
        display: block;
        width: 30px;
        height: 100%;
        clip-path: polygon(102% 0%, 102% 100%, 0% 100%);
        content: "";
        background-color: $color-accent;
      }
      &::after {
        position: absolute;
        left: 100%;
        display: block;
        width: 100vw;
        height: 100%;
        content: "";
        background-color: $color-accent;
      }
    }
    @include mq(hd) {
      position: relative;
      z-index: 0;
      align-items: center;
      height: 50px;
      font-size: 12px;
      &::before {
        position: absolute;
        top: 0%;
        left: 50%;
        z-index: -1;
        display: block;
        width: 100vw;
        height: 100%;
        content: "";
        background-color: $color-accent;
        transform: translateX(-50%);
      }
    }
  }
}

.nav-ftBottom {
  padding-left: 0;
  list-style: none;
  @include mq(min-hd) {
    display: flex;
    gap: 3em;
  }
  @include mq(hd) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(11em, 1fr));
    gap: 1em;
  }
  &_item {
  }
  &_link {
    transition: opacity 0.3s;
    @media (hover: hover) {
      &:hover {
        // text-decoration: underline;
        opacity: 0.5;
      }
    }
  }
}

.box-cookieConsent {
  position: fixed;
  bottom: 0;
  z-index: 100;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1.2em;
  line-height: 1.8;
  color: #fff;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.7);
  @include mq(min-md) {
    gap: 30px;
    font-size: 12px;
  }
  @include mq(md) {
    flex-direction: column;
    gap: 0.5em;
    font-size: 10px;
  }
  &.-show {
    visibility: visible;
  }
  a {
    color: #fff;
  }
  .btn {
    margin: 0;
  }
  &.-hide {
    animation: hide 0.3s linear 0s;
    animation-fill-mode: forwards;
  }
}
@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    visibility: hidden;
    opacity: 0;
  }
}
