@use "../abstracts" as *;
@use "sass:math";

.sec-form {
  .panel {
    @include mq(min-md) {
      padding: math.percentage(math.div(40, 1100));
    }
    @include mq(md) {
      padding: 30px math.percentage(math.div(24, 327));
    }
  }
}

.formSet {
  p + & {
    margin-top: 1.5em;
  }
  input,
  textarea,
  select {
    width: 100%;
    line-height: 1.4;
    background-color: #f4f4f4;
    border: 1px solid #ddd;
    border-radius: 0;
    @include mq(min-md) {
      padding: 0.5em 0.5em;
    }
    @include mq(md) {
      padding: 0.8em 0.5em;
    }
    &::placeholder {
      color: #a7a7a7;
    }
  }
  input {
    &[type="date"] {
      @include mq(md) {
        min-width: 10em;
        height: 47px;
        padding: 0.5em 1.5em;
      }
    }
  }
  select {
    padding: 0.55em 0.5em;
    padding-right: 1.5em;
    background-image: url("/assets/images/common/arw-down.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 0.3em) center;
    background-size: 15px 15px;
    border-radius: 0px;
    appearance: none;
  }
  .btn {
    flex-direction: row-reverse;
    a,
    button {
      min-width: 205px;
      &[disabled] {
        color: #a7a7a7;
        cursor: default;
        background-color: #dddddd;
      }
    }
  }
}

.tb-form {
  width: 100%;
  line-height: 1.38;
  @include mq(md) {
    border-bottom: 1px solid #ddd;
    &,
    tbody,
    tr,
    th,
    td {
      display: block;
    }
  }
  th,
  td {
    padding: 1em;
    text-align: left;
    border: 1px solid #ddd;
    @include mq(min-md) {
    }
    @include mq(md) {
      border-bottom-style: none;
    }
  }
  tbody {
    tr {
      &.-require {
        th {
          position: relative;
          padding-left: 4em;
          &::before {
            position: absolute;
            left: 1em;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 22px;
            font-size: 12px;
            line-height: 1;
            color: #fff;
            content: "必須";
            background-color: #c31616;
          }
        }
      }
    }
    th {
      background-color: #f4f4f4;
      @include mq(min-md) {
        width: 280px;
        .sideNav_content & {
          width: 212px;
        }
      }
      @include mq(md) {
        width: auto;
        padding-top: 12px;
        padding-bottom: 12px;
      }
      span.-small {
        display: inline-block;
        font-size: 12px;
      }
    }
  }
  p + textarea {
    margin-top: 1em;
  }
}

.form-checkBox {
  display: flex;
  padding-left: 0;
  list-style: none;
  @include mq(min-md) {
    flex-wrap: wrap;
    gap: 1em 2em;
  }
  @include mq(md) {
    flex-direction: column;
    gap: 24px;
  }
  &.-center {
    align-items: center;
    justify-content: center;
  }
  &_item {
    label {
      display: flex;
      gap: calc(12em / 16);
      align-items: center;
      cursor: pointer;
      @media (hover: hover) {
        &:hover {
          i {
            border-color: $color-accent;
          }
        }
      }
    }
    i {
      position: relative;
      display: inline-block;
      aspect-ratio: 24 / 24;
      background-image: url("/assets/images/common/icon-checkBox.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      border: 1px solid #ddd;
      border-radius: 0.5em;
      transition: background-color 0.2s, border-color 0.2s;
      @include mq(min-md) {
        width: calc(24em / 16);
        min-width: calc(24em / 16);
      }
      @include mq(md) {
        width: 32px;
        min-width: 32px;
      }
    }
    input {
      display: none;
      &:checked {
        + label i {
          background-color: $color-accent;
          border-color: $color-accent;
        }
      }
    }
  }
}

.form-radioBtn {
  display: flex;
  padding-left: 0;
  list-style: none;
  @include mq(min-md) {
    flex-wrap: wrap;
    gap: 1em 2em;
  }
  @include mq(md) {
    flex-direction: column;
    gap: 24px;
  }
  &_item {
    label {
      display: flex;
      align-items: center;
      cursor: pointer;
      @include mq(min-md) {
        gap: calc(12em / 16);
      }
      @include mq(md) {
        gap: 12px;
      }
      @media (hover: hover) {
        &:hover {
          i {
            border-color: $color-accent;
          }
        }
      }
    }
    i {
      position: relative;
      display: inline-block;
      aspect-ratio: 24 / 24;
      border: 1px solid #ddd;
      border-radius: 50%;
      transition: background-color 0.2s, border-color 0.2s;
      @include mq(min-md) {
        width: calc(24em / 16);
      }
      @include mq(md) {
        flex: 0 0 auto;
        width: 32px;
      }
      &::before {
        position: absolute;
        inset: 0;
        display: block;
        aspect-ratio: 1 / 1;
        margin: auto;
        content: "";
        background-color: $color-accent;
        border-radius: 50%;
        opacity: 0;
        transition: opacity 0.2s;
        @include mq(min-md) {
          width: 10px;
        }
        @include mq(md) {
          width: 12px;
        }
      }
    }
    input {
      display: none;
      &:checked {
        + label i {
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }
}

.form-rankingSearch {
  .form-selectBox {
    width: 100%;
    min-width: 10em;
    min-height: 50px;
    padding-right: 3.5em;
    background-color: $color-bg-base;
    background-image: url("/assets/images/common/arw-triangle.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 24px) center;
    background-size: 16px 10px;
    border: 1px solid #ddd;
    // border-radius: 0;
    appearance: none;
  }
}

.form-text {
  display: flex;
  padding-left: 0;
  list-style: none;
  @include mq(min-md) {
    flex-wrap: wrap;
    gap: 16px math.percentage(math.div(24, 473));
  }
  @include mq(md) {
    flex-direction: column;
    gap: 15px;
  }
  &_item {
    display: flex;
    flex: 1;
    @include mq(min-md) {
      gap: 12px;
      align-items: center;
    }
    @include mq(md) {
      flex-direction: column;
      gap: 6px;
    }
  }
  &_ttl {
    white-space: nowrap;
  }
  &_input {
    flex: 1 1 auto;
  }
  &.-date & {
    &_input {
      display: flex;
      flex: none;
      gap: 0.3em;
      align-items: center;
      &.-time {
        align-self: flex-start;
      }
    }
  }
}

.form-tel {
  display: flex;
  gap: 31px;
  align-items: center;
  padding-left: 0;
  list-style: none;
  &_item {
    position: relative;
    width: 100px;
    & + & {
      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 31px;
        height: 1em;
        margin: auto;
        line-height: 1;
        text-align: center;
        content: "‐";
        transform: translateX(-100%);
      }
    }
  }
}

.form-note {
  border: 1px solid #ddd;
  @include mq(min-md) {
    padding: calc(24em / 16);
    margin-top: 32px;
  }
  @include mq(md) {
    padding: 24px 19px;
    margin-top: 25px;
  }
  &_ttl {
    font-weight: bold;
    line-height: 1.5;
    @include mq(min-md) {
      font-size: 16px;
    }
    @include mq(md) {
      font-size: 15px;
    }
    + * {
      margin-top: 0.5em;
    }
  }
  + .form-checkBox {
    margin-top: 40px;
  }
}

.message {
  padding-bottom: 60px;
  text-align: center;
  &_img {
    // width: 167px;
    // max-width: math.percentage(math.div(144, 327));
    margin: 0 auto;
  }
  &_txt {
    margin-top: 30px;
  }
  &_ttl {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.5;
  }
}
